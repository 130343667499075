import { useState, useEffect } from "react";
import {
    DatePicker,
    Row,
    Col,
    Typography,
    Select,
    Card
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { FUEL } from "../../utils/constants";
import dayjs from "dayjs";
import { setSelectedFuelId, setSelectedFuelSourceId, setSelectedTimePeriod } from "../../redux/slices/powerSlice";
import { fetchAndFilterGraphData, fetchBPValues } from "../../redux/services/powerService";
import TimezoneSelector from "../general/TimezoneSelector";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { setFromDate, setToDate } from "../../redux/slices/customerSlice";
dayjs.extend(utc);
dayjs.extend(timezone);


const Fuel = () => {
    const responsiveCol = { xxl: 8, xl: 8, lg: 8, md: 8, sm: 24, xs: 24 };
    const { emptyFuelId, fuelGenList: generatorListfromAPI, loading: isLoadingGeneratorList, selectedFuelSourceId, selectedFuelId, graphDataLoading, selectedTimePeriod, fuelBpName } = useSelector((state: RootState) => state.power);
    const { selectedCustomerName, customerSites, selectedSite, toDate, fromDate } = useSelector((state: RootState) => state.userInfo);
    const [generatorList, setGeneratorList] = useState<any[]>([]);
    // const [fromDate, setFromDate] = useState<any>(dayjs().startOf('day'));
    // const [toDate, setToDate] = useState<any>(dayjs().endOf('day'));
    const [dateRange, setDateRange] = useState<any>([null, null]);
    const dispatch = useDispatch<AppDispatch>();
    const { fuelId } = useParams();
    const selectedSiteObj = customerSites.find((data) => data.name === selectedSite);
    const present_components: any = selectedSiteObj?.preferences?.present_components;
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedFuelId !== null && !fuelId) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}/${selectedFuelId}`);
        }
    }, [selectedFuelId]);

    useEffect(() => {
        if (emptyFuelId) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}`);
        }
    }, [emptyFuelId])

    useEffect(() => {
        if (fuelId && generatorList.length > 0 && !isLoadingGeneratorList) {
            if (generatorList.filter(item => item.value === Number(fuelId)).length > 0) {
                dispatch(setSelectedFuelId(Number(fuelId)));
                dispatch(setSelectedFuelSourceId(generatorList.find(item => item.value === Number(fuelId))?.source_id) || '');
            } else {
                dispatch(setSelectedFuelId(generatorList[0].value));
                dispatch(setSelectedFuelSourceId(generatorList[0].source_id) || '');
                navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}/${generatorList[0].value}`);
            }
        }
    }, [fuelId, generatorList]);

    function getDateTimeRange(option: any) {
        const currentDate = dayjs();
        let startDate, endDate;

        switch (option) {
            case "Last 24 hours":
                startDate = currentDate.subtract(24, 'hour');
                endDate = currentDate;
                break;
            case "Today":
                startDate = currentDate.startOf('day');
                endDate = currentDate.endOf('day');
                break;
            case "Last 7 Days":
                startDate = currentDate.subtract(7, 'day');
                endDate = currentDate;
                break;
            case "Last 30 Days":
                startDate = currentDate.subtract(30, 'day');
                endDate = currentDate;
                break;
            case "This Week":
                startDate = currentDate.startOf('week');
                endDate = currentDate.endOf('week');
                break;
            case "This Month":
                startDate = currentDate.startOf('month');
                endDate = currentDate.endOf('month');
                break;
            default:
                startDate = currentDate.startOf('day');
                endDate = currentDate;
                break;
        }

        // Setting fromDate and toDate for the API call to plot graph data based on the time period changes
        startDate.format('YYYY-MM-DD') !== fromDate && dispatch(setFromDate(startDate.toISOString()));
        endDate.format('YYYY-MM-DD') !== toDate && dispatch(setToDate(endDate.toISOString()));
    }

    function returngetDateTimeRange(option: any) {
        const currentDate = dayjs();
        let startDate, endDate;

        switch (option) {
            case "Last 24 hours":
                startDate = currentDate.subtract(24, 'hour');
                endDate = currentDate;
                break;
            case "Today":
                startDate = currentDate.startOf('day');
                endDate = currentDate.endOf('day');
                break;
            case "Last 7 Days":
                startDate = currentDate.subtract(7, 'day');
                endDate = currentDate;
                break;
            case "Last 30 Days":
                startDate = currentDate.subtract(30, 'day');
                endDate = currentDate;
                break;
            case "This Week":
                startDate = currentDate.startOf('week');
                endDate = currentDate.endOf('week');
                break;
            case "This Month":
                startDate = currentDate.startOf('month');
                endDate = currentDate.endOf('month');
                break;
            default:
                startDate = currentDate.startOf('day');
                endDate = currentDate;
                break;
        }

        return { startDate, endDate }
    }

    const handleEquipmentChange = (e: any) => {
        e && dispatch(setSelectedFuelId(e));
        e && dispatch(setSelectedFuelSourceId(generatorList.find(item => item.value === e)?.source_id) || '');
        e && navigate(`${e}`);
    };

    // useEffect(() => {

    // }, [selectedTimePeriod])

    useEffect(() => {
        if (selectedTimePeriod) {
            getDateTimeRange(selectedTimePeriod);
        }
        if (selectedFuelId && selectedSite && selectedTimePeriod && selectedFuelId === Number(fuelId) && selectedTimePeriod !== 'date' && selectedFuelSourceId && fuelBpName) {
            dispatch(fetchAndFilterGraphData({
                selectedCustomerName,
                avail_sites: customerSites,
                selectedSite,
                fromDate: returngetDateTimeRange(selectedTimePeriod).startDate?.toISOString(),
                toDate: returngetDateTimeRange(selectedTimePeriod).endDate?.toISOString(),
                bpValue: fuelBpName,
                selectedGeneratorId: selectedFuelId || 0,
                sourceId: selectedFuelSourceId
            }));
        }
    }, [selectedFuelId, selectedSite, fuelId, selectedFuelSourceId, selectedTimePeriod, fuelBpName]);

    useEffect(() => {
        if (selectedFuelId === Number(fuelId) && selectedFuelSourceId && generatorList.find(item => item.value === selectedFuelId)) {
            // dispatch(fetchBPLiveData({ selectedCustomerName: selectedCustomerName, avail_sites: customerSites, selectedGeneratorId: selectedGeneratorId, selectedSite: selectedSite }));
            dispatch(fetchBPValues({ selectedCustomerName: selectedCustomerName, avail_sites: customerSites, selectedGeneratorId: selectedFuelId, selectedSite: selectedSite, sourceId: selectedFuelSourceId }));
        }
    }, [selectedFuelId, fuelId, selectedFuelSourceId, generatorList]);

    const handleTimePeriodChange = (timePeriod: string) => {
        setDateRange([null, null]);
        dispatch(setSelectedTimePeriod(timePeriod));
        getDateTimeRange(timePeriod); // Added to update date range when time period changes
    };

    const { Option } = Select;
    const { RangePicker } = DatePicker;

    const filterDateTime = (range: any) => {
        setDateRange(range);
        const [from, to] = range;
        dispatch(setFromDate(from));
        dispatch(setToDate(to));

        range && dispatch(fetchAndFilterGraphData({
            selectedCustomerName,
            avail_sites: customerSites,
            selectedSite,
            fromDate: from?.toISOString(),
            toDate: to?.toISOString(),
            bpValue: fuelBpName,
            selectedGeneratorId: selectedFuelId || 0,
            sourceId: selectedFuelSourceId
        }));
    };

    useEffect(() => {
        if (generatorListfromAPI) {
            setGeneratorList(generatorListfromAPI
                .filter(info => info.equipment_name && !['EV Charger', 'EV_Charger'].includes(info.equipment_name))
                .map(info => ({
                    value: info.equipment_id,
                    label: info.equipment_name,
                    source_id: info.source_id
                }))
            );
        }
    }, [generatorListfromAPI]);

    return (
        <Row justify="start" style={{ paddingBottom: "10px" }}>
            <Col span={24}>
                <Card title="Fuel History">
                    <Row justify="start" className="rowGraph" gutter={[16, 16]}>
                        <Col span={24}>
                            <Row>
                                <Col {...responsiveCol}>
                                    {((generatorList && generatorList[0]) || isLoadingGeneratorList) && <Select
                                        value={selectedFuelId}
                                        style={{ width: 120 }}
                                        // allowClear={true}
                                        onClear={() => {
                                            dispatch(setSelectedFuelId(null));
                                            dispatch(setSelectedFuelSourceId(''));
                                            navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}`);
                                        }}
                                        options={generatorList}
                                        loading={isLoadingGeneratorList}
                                        onChange={handleEquipmentChange}
                                        className="hidden"
                                    />}
                                </Col>
                                <Col {...responsiveCol}>
                                    <Typography.Paragraph style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'center' }}>
                                        Filter by:
                                        <Select
                                            value={selectedTimePeriod}
                                            style={{ width: 150 }}
                                            onChange={handleTimePeriodChange}
                                            options={[
                                                { value: "Last 24 hours", label: "Last 24 hours" },
                                                { value: "Today", label: "Today" },
                                                { value: "Last 7 Days", label: "Last 7 Days" },
                                                { value: "Last 30 Days", label: "Last 30 Days" },
                                                { value: "This Week", label: "This Week" },
                                                { value: "This Month", label: "This Month" },
                                                { value: "date", label: "Custom" },
                                            ]}
                                        />
                                        {selectedTimePeriod === "date" && (
                                            <RangePicker
                                                showTime
                                                value={dateRange}
                                                format="MM/DD/YYYY HH:mm"
                                                onChange={filterDateTime}
                                                style={{ width: 300 }}
                                            />
                                        )}
                                    </Typography.Paragraph>
                                </Col>

                                <Col {...responsiveCol}>
                                    <Typography.Paragraph>
                                        Timezone: &nbsp;&nbsp;
                                        <TimezoneSelector />
                                        {/* <Select
                                            defaultValue={selectedTimezone}
                                            style={{ width: 120 }}
                                            onChange={updateTimezone}
                                            showSearch
                                            optionFilterProp="label"
                                            filterOption={(input: string, option: any) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {TIMEZONES.map((timezone) => (
                                                <Option key={timezone.value} value={timezone.value} label={timezone.label}>
                                                    {timezone.label}
                                                </Option>
                                            ))}
                                        </Select> */}
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{ height: '20px' }}></div>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Outlet />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default Fuel;
