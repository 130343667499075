import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../rootreducer";
import { useNavigate } from "react-router-dom";

interface FactoryViewWrapperProps {
    children: ReactNode;
}

const FactoryViewWrapper: React.FC<FactoryViewWrapperProps> = ({ children }) => {
    const { permittedCustomers,
        permittedCustomersLoading,
        permittedCustomersError,
        customerSites,
        selectedCustomerName,
        FactoryPath,
        selectedTimezone,
        loadingLogout,
        selectedSite,
        userInfo,
        loading,
        customerSitesLoading,
        theme: iconThemeFromRedux,
        customerSitesError } = useSelector((state: RootState) => state.userInfo);

    const navigate = useNavigate();

    const FactoryPathUrl = FactoryPath && window.location.pathname.toLowerCase().includes('factorydashboard');

    useEffect(() => {
        if (selectedCustomerName && permittedCustomers && FactoryPathUrl) {
            const check = permittedCustomers.find((data) => data.customer === selectedCustomerName && data.preferences?.factory === true && permittedCustomers.find((data) => data.customer === selectedCustomerName && data.preferences?.factory !== undefined));
            if (!check) {
                navigate('/403')
            }
        }
    }, [permittedCustomers, selectedCustomerName]);
    if (selectedCustomerName && permittedCustomers && FactoryPathUrl) {
        const check = permittedCustomers.find((data) => data.customer === selectedCustomerName && data.preferences?.factory === true && permittedCustomers.find((data) => data.customer === selectedCustomerName && data.preferences?.factory !== undefined));
        if (check) {
            return <>{children}</>;
        }
    }
    return <></>;

};

export default FactoryViewWrapper;
