import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from './store';
import { Col, Row, Spin, Typography } from 'antd';

type ProtectedRouteProps = {
    route: string;
    children: React.ReactNode;
};

const ProtectedRoute = ({ route, children }: ProtectedRouteProps) => {
    const { customerSites, customerSitesLoading, permittedCustomersLoading, selectedSite } = useSelector((state: RootState) => state.userInfo);

    if (customerSites.length > 0) {
        const selectedSiteData = customerSites.find(site => site.name === selectedSite);
        const presentComponents = selectedSiteData?.preferences?.present_components || [];

        if (presentComponents.map(comp => comp.toLowerCase()).includes(route.toLowerCase())) {
            return <>{children}</>;
        }
    }

    if (customerSitesLoading || permittedCustomersLoading || !selectedSite) {
        return (
            <Spin size="large" style={{ backgroundColor: 'transparent' }}>
                <Row justify="center">
                    <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
                        <Typography.Title level={2} type="secondary">
                            Loading. Please wait!
                        </Typography.Title>
                    </Col>
                </Row>
            </Spin>
        );
    }

    // Ensure a return value in all cases
    return <Navigate to="/403" replace />;
};

export default ProtectedRoute;
