import { Card, Empty } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export default function Solar() {
    const customerSites = useSelector((state: RootState) => state.userInfo.customerSites);
    const selectedSite = useSelector((state: RootState) => state.userInfo.selectedSite);

    // Fetch the victron_link for the selected site
    const victronSite = customerSites.find((site) => site.name === selectedSite)?.victron_link;

    return (
        <Card
            title="SOLAR"
            className="w-full"
            bodyStyle={{ height: '100%', padding: 0 }}
        >
            {victronSite ? <iframe
                title="victron-iframe"
                style={{
                    width: '100%',
                    height: 'calc(100vh - 160px)', // Dynamically adjust height
                    border: 'none',
                }}
                src={victronSite || ''}
            ></iframe> : <Empty description="No Solar Data Attached to this Site" />}
        </Card>
    );
}
