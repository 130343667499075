import { useEffect, useRef } from 'react';
import './Dashboard.css';
import { matchPath, Outlet, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootreducer';
import { AppDispatch } from '../../store';
import { setSelectedSite } from '../../redux/slices/customerSlice';
import { CHARGERS, FUEL, GENERAL, POWER_GENERATION } from '../../utils/constants';
import { fetchLiveDataAcrossSitesForSourceIds } from '../../redux/services/chargerSevice';
import { fetchWeatherData } from '../../redux/services/customerService';
import { fetchBPLiveData, fetchGeneratorList } from '../../redux/services/powerService';
import { setSelectedFuelId, setSelectedFuelSourceId, setSelectedGeneratorId } from '../../redux/slices/powerSlice';
import { fetchBPLiveDataForCustomerForOneSite, fetchdetailedSummaryChargerForSite } from '../../redux/services/dashboardService';
import dayjs from 'dayjs';

const Dashboard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { permittedCustomers,
        customerSites,
        selectedCustomerName,
        selectedSite } = useSelector((state: RootState) => state.userInfo);

    const { generatorList, selectedFuelId, fuelGenList, loading, selectedGeneratorId } = useSelector((state: RootState) => state.power);

    const presentComponents = customerSites.find((site) => site.name === selectedSite)?.preferences?.present_components;

    useEffect(() => {
        const pollApis = () => {
            // Conditionally dispatch based on presence in presentComponents
            if (presentComponents?.includes(CHARGERS)) {
                dispatch(
                    fetchdetailedSummaryChargerForSite({
                        selectedCustomerName,
                        fromDate: dayjs().subtract(1, 'day').toISOString(), // Serialized date
                        toDate: dayjs().toISOString(), // Serialized date
                        site_id: customerSites.find((x) => x.name === selectedSite)?.id, // Use `find` for cleaner code
                    })
                );
            }

            if (presentComponents?.includes(POWER_GENERATION) || presentComponents?.includes(FUEL) || presentComponents?.includes(GENERAL)) {
                selectedCustomerName === 'bchydro' && dispatch(fetchLiveDataAcrossSitesForSourceIds({ selectedSite, customerSites, selectedCustomerName, source_id: 'BCH-Fuel' }))
                dispatch(fetchGeneratorList({ selectedCustomerName, avail_sites: customerSites, selectedSite }));
                dispatch(fetchBPLiveDataForCustomerForOneSite({ selectedCustomerName, site_id: customerSites.filter(site => site.name === selectedSite)[0].id }))
            }
        };

        pollApis(); // Call immediately on mount
        const interval = setInterval(pollApis, 300000); //5min

        // Cleanup the interval when component unmounts
        return () => clearInterval(interval);
    }, [selectedSite]);

    useEffect(() => {
        const logUrlOnBack = () => {
        };

        // Add event listener for the popstate event
        window.addEventListener("popstate", logUrlOnBack);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("popstate", logUrlOnBack);
        };
    }, []);

    useEffect(() => {
        if (selectedSite && selectedCustomerName) {
            document.title = (permittedCustomers && permittedCustomers.filter(item => item.customer === selectedCustomerName)[0].name ?
                permittedCustomers.filter(item => item.customer === selectedCustomerName)[0].name : selectedCustomerName) + ' - ' + selectedSite + ' - ' + 'e-Boost' || 'e-BoostRealM';
        }
        else {
            document.title = 'e-BoostRealM';
        }
    }, [selectedSite, selectedCustomerName]);

    const location = useLocation();

    const { site, powerId: powerIdFromURL }: any = useParams();

    useEffect(() => {
        if (site !== selectedSite) {
            const siteExists = customerSites.find((data) => data.name === site);
            if (siteExists)
                dispatch(setSelectedSite(siteExists.name))
        }
    }, [site])

    const prevLat = useRef<number | null>(null);
    const prevLon = useRef<number | null>(null);


    const siteLocation: any = customerSites.find(
        (site) => site.name === selectedSite,
    )?.location;

    useEffect(() => {
        if (siteLocation?.latitude && siteLocation?.longitude && selectedCustomerName && location.pathname.toLocaleLowerCase().includes('general')) {
            const { latitude, longitude } = siteLocation;

            if (latitude !== prevLat.current || longitude !== prevLon.current) {
                prevLat.current = latitude;
                prevLon.current = longitude;

                // Dispatch fetchWeatherData only if the coordinates have changed
                dispatch(fetchWeatherData({ lat: latitude, lon: longitude, selectedCustomerName }));
            }
        }
    }, [siteLocation, location, selectedCustomerName]);


    const isPowerRoute = matchPath({ path: `/:customer/:site/${POWER_GENERATION}`, end: true }, location.pathname);
    const isSpecificPowerRoute = matchPath({ path: `/:customer/:site/${POWER_GENERATION}/:powerId`, end: true }, location.pathname);

    useEffect(() => {
        if (generatorList && loading === false && generatorList.find(generator => generator.equipment_id === selectedGeneratorId)) {
            if (selectedGeneratorId && (isPowerRoute || (isSpecificPowerRoute && selectedGeneratorId === Number(powerIdFromURL)))) {
                // Call the action immediately on first load
                dispatch(fetchBPLiveData({
                    selectedCustomerName,
                    avail_sites: customerSites,
                    selectedGeneratorId,
                    selectedSite,
                }));
            }
        }
    }, [generatorList, selectedGeneratorId, powerIdFromURL])


    useEffect(() => {
        if (generatorList && generatorList.length > 0 && !loading) {
            !selectedGeneratorId && dispatch(setSelectedGeneratorId(generatorList[0].equipment_id));
        }
    }, [generatorList, loading]);

    useEffect(() => {
        if (fuelGenList && fuelGenList.length > 0 && !loading) {
            !selectedFuelId && dispatch(setSelectedFuelId(fuelGenList[0].equipment_id));
            !selectedFuelId && dispatch(setSelectedFuelSourceId(fuelGenList[0].source_id));
        }
    }, [fuelGenList, loading]);

    return (
        <div className='dashboard'>
            <Outlet />
        </div >
    )
}

export default Dashboard;