import { Card, Col, Empty, Row, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootreducer';

export default function StarLink() {
    const customerSites = useSelector((state: RootState) => state.userInfo.customerSites);

    const selectedSite = useSelector((state: RootState) => state.userInfo.selectedSite);

    const starLink = customerSites.find(site => site.name === selectedSite)?.communication_link;


    return (
        <Card title="Starlink Communication">
            {starLink ? <iframe
                title="starlink-iframe"
                style={{ width: "100%", height: "calc(100vh - 210px)", border: "none", backgroundColor: "none" }}
                src={starLink}
            ></iframe> : <Empty description="No starlink connection available" />}
        </Card>
    );
}
