import React from "react";
import { useRouteError } from "react-router-dom";
import { Result, Button } from "antd";

function ErrorPage() {
    // Get error details
    const error: any = useRouteError();

    return (
        <Result
            status="404"
            title="Oops! Something went wrong."
            subTitle={
                <>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error.statusText || error.message}</i>
                        <br />
                        <i>Possible reason: Invalid route or URL.</i>
                    </p>
                </>
            }
            extra={
                <Button type="primary" href="/">
                    Go Back to Home
                </Button>
            }
        />
    );
}

export default ErrorPage;
