import { Card, Col, Row, Spin, Table, Tooltip, Typography } from "antd";
import './General.css';  // Assuming you add the CSS here
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import '../dashboard/Dashboard.css';
import MapsGlobal from "../maps/MapsGlobal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { setSelectedSite } from "../../redux/slices/customerSlice";
import _ from "lodash";
import hrsSpnt from '../../assets/Dashboard/HoursSpentchargingV2.svg';
import kwhgen from '../../assets/Dashboard/KwhGenV2.svg';
import chgTdy from '../../assets/Dashboard/ChargingSessTdyV2.svg';
import { fetchBPLiveDataForCustomer, fetchdetailedSummaryCharger, fetchMaintenanceThreshold } from "../../redux/services/dashboardService";
import dayjs from "dayjs";
import { clearPowerState } from "../../redux/slices/powerSlice";
import { clearChargerData } from "../../redux/slices/chargerSlice";
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import TableDashboard from "./TableDashboard";
// Extend Day.js with the duration plugin
dayjs.extend(duration);
dayjs.extend(timezone);

interface ColorCircleProps {
    color: string;  // The color for the circle background
    value: number;  // The number or text to display in the center
}

const GlobalDashboard3 = () => {
    const { permittedCustomers, permittedCustomersLoading, selectedCustomerName, customerSites, customerSitesLoading, userInfo, theme: iconThemeFromRedux, selectedTimezone, selectedTimezoneAbb, selectedSite } = useSelector((state: RootState) => state.userInfo);
    const { detailedChargerData, loadingDetailedChargerData, detailedGeneratorData, loadingGenDetails } = useSelector((state: RootState) => state.dashboard);
    const loadingLength = Object.keys(detailedChargerData).length > 0 && Object.keys(detailedGeneratorData).length > 0;
    const dispatch = useDispatch<AppDispatch>();
    const { customer, site } = useParams();

    const responsiveColCards = { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24 };

    const responsiveColCardsInner = { xxl: 12, xl: 12, lg: 12, md: 12, sm: 24, xs: 24 };

    useEffect(() => {
        dispatch(clearPowerState());
        dispatch(clearChargerData());
        dispatch(setSelectedSite(''));
    }, []);

    const [siteKwhDetails, setSiteKwhDetails] = useState([{ kWh: 0, site_id: '' }]);
    const [totalKwh, setTotalKwh] = useState(0);

    // Function to calculate KWH sums
    const calculateKwhSums = () => {
        let totalKWH = 0;
        const siteKwhArray: any = [];

        if (!loadingGenDetails && detailedGeneratorData && detailedGeneratorData.length > 0) {
            const siteKwhMap: any = {};

            detailedGeneratorData.forEach(item => {
                const siteId = item.site_id;

                item.equipment_data.forEach(item2 => {
                    item2.LiveStatus === 'live' && item2.data.forEach(item3 => {
                        if (item3.value_name === 'kWh') {
                            const kwhValue = Number(item3.value < 0 ? 0 : item3.value);

                            // Accumulate the total KWH
                            totalKWH += kwhValue;

                            // Accumulate KWH per site_id
                            if (!siteKwhMap[siteId]) {
                                siteKwhMap[siteId] = 0;
                            }
                            siteKwhMap[siteId] += kwhValue;
                        }
                    });
                });
            });

            // Convert siteKwhMap to an array of objects with site_id and kWh
            for (const [siteId, kwhSum] of Object.entries(siteKwhMap)) {
                siteKwhArray.push({ site_id: siteId, kWh: _.round(kwhSum as number, 2) });
            }
        }

        // Update state with the calculated values
        setTotalKwh(_.round(totalKWH, 2));
        setSiteKwhDetails(siteKwhArray);
    };

    // Recalculate KWH sums whenever `detailedGeneratorData` or `loadingGenDetails` changes
    useEffect(() => {
        calculateKwhSums();
    }, [detailedGeneratorData, loadingGenDetails]);

    const getSiteNamesWithKwh = () => {
        // Prepare the data for the table
        const data = siteKwhDetails.map(site => {
            const matchingSite = customerSites.find(item => item.id.toString() === site.site_id);
            return {
                key: site.site_id,
                siteName: matchingSite ? matchingSite.name : 'Unknown Site',
                kWh: site.kWh
            };
        });

        // Define the table columns
        const columns = [
            {
                title: 'Site Name',
                dataIndex: 'siteName',
                key: 'siteName',
            },
            {
                title: 'kWh',
                dataIndex: 'kWh',
                key: 'kWh',
            },
        ];

        // Return the table
        return (
            <Table
                columns={columns}
                dataSource={data}
                pagination={false} // Disable pagination for tooltip content
                size="small" // Use a compact table
                bordered // Optional: Adds border to the table
            />
        );
    };

    const getChargingSessionsPerWeek = () => {
        if (!loadingDetailedChargerData && detailedChargerData.session && detailedChargerData.session.length > 0) {
            return detailedChargerData.session.filter(item => item.status === 'Ended').length
        }
        else return '0';
    }

    const getHoursSpentChargingWeek = () => {
        // Ensure data exists and is in the expected format
        if (!loadingDetailedChargerData && detailedChargerData?.session?.length > 0) {
            // Filter sessions where status is 'Ended'
            const endedStats = detailedChargerData.session.filter(
                (item) => item.status === 'Ended'
            );

            // Accumulate the total seconds
            const totalSeconds = endedStats.reduce((acc, item) => {
                // Ensure metrics.energyPeriod is a valid number
                const energyPeriod = Number(item.metrics?.energyPeriod) || 0;
                return acc + energyPeriod;
            }, 0);

            // Handle potential edge cases
            if (isNaN(totalSeconds) || totalSeconds <= 0) {
                return '0';
            }

            // Calculate total hours using dayjs
            const duration = dayjs.duration(totalSeconds, 'seconds');
            const hours = duration.asHours();

            // Return rounded result to 3 decimal places
            return _.round(hours, 3);
        }

        // Default return value for invalid or missing data
        return '0';
    };

    useEffect(() => {
        let pollInterval: NodeJS.Timeout | null = null;

        const pollApis = () => {
            if (
                selectedCustomerName === customer &&
                !_.isEmpty(selectedCustomerName) &&
                detailedChargerData.all_connected_chargers.length === 0 &&
                detailedChargerData.session.length === 0 &&
                detailedChargerData.connector.length === 0 &&
                detailedGeneratorData.length === 0 && !loadingDetailedChargerData && !loadingGenDetails
            ) {
                dispatch(fetchBPLiveDataForCustomer({ selectedCustomerName }));
                dispatch(fetchdetailedSummaryCharger({ selectedCustomerName }));
                // dispatch(fetchMaintenanceThreshold({ selectedCustomerName: selectedCustomerName }));
            }
        };

        // Call immediately on mount
        pollApis();

        // Set up polling every 3 minutes (180,000 ms)
        pollInterval = setInterval(() => {
            pollApis();
        }, 5 * 60 * 1000);

        // Cleanup on unmount
        return () => {
            if (pollInterval) clearInterval(pollInterval);
        };
    }, [selectedCustomerName, customer]);


    useEffect(() => {
        if (selectedCustomerName) {
            document.title = (permittedCustomers && permittedCustomers.filter(item => item.customer === selectedCustomerName)?.[0]?.name ?
                permittedCustomers.filter(item => item.customer === selectedCustomerName)[0].name : selectedCustomerName) + ' - ' + 'e-Boost' || 'e-BoostRealM';
        }
        else {
            document.title = 'e-BoostRealM';
        }
    }, [selectedCustomerName]);

    const { Title } = Typography;

    return (
        <div className="dashboard general">
            {
                <div>
                    <Row gutter={[12, 12]} className="full-height-row">
                        {/* Left Side */}
                        <Col {...responsiveColCards}>
                            <Row gutter={[12, 12]}>
                                <Col {...responsiveColCardsInner}>
                                    <Card style={{ minHeight: '142px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} size='small' >
                                        {
                                            permittedCustomers.filter(item => item.customer === selectedCustomerName)[0]?.customer_logo ? <img src={permittedCustomers.filter(item => item.customer === selectedCustomerName)[0]?.customer_logo} alt="customerLogo" style={{ height: '110px', width: '100%' }} /> : <Title level={1} >{permittedCustomers.filter(item => item.customer === selectedCustomerName)[0]?.name}</Title>
                                        }
                                    </Card>
                                </Col>
                                <Col {...responsiveColCardsInner}>
                                    <Card className="NoHeaderBorder" style={{ minHeight: '142px' }} size='small' title={_.toUpper("Hours Spent Charging (WEEK)")} extra={<img src={hrsSpnt} alt="fuel" className='h-40' />}>
                                        <Spin size="large" spinning={(permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading) || loadingDetailedChargerData)}><Title level={1} className="typoNumber">{getHoursSpentChargingWeek()}</Title></Spin>
                                    </Card>
                                </Col>
                            </Row>
                            {/* Space between the two rows */}
                            <Row gutter={[12, 12]} style={{ marginTop: '12px' }}>
                                <Col {...{ ...responsiveColCardsInner }}><Card className="NoHeaderBorder" style={{ minHeight: '142px' }} size='small' title={_.toUpper("Charging Sessions (week)")} extra={<img src={chgTdy} alt="generator" className='h-40' />}>
                                    <Spin size="large" spinning={(permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading) || loadingDetailedChargerData)}><Title level={1} className="typoNumber">{getChargingSessionsPerWeek()}</Title></Spin>
                                </Card></Col>
                                <Col {...responsiveColCardsInner}>
                                    <Card style={{ minHeight: '142px' }} className="NoHeaderBorder" size='small' title={_.toUpper("KWh Generated")} extra={<img src={kwhgen} alt="battery" className='h-40' />}>
                                        <Spin size="large" spinning={(permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading) || loadingGenDetails)}>
                                            <Title level={1} className="typoNumber"><Tooltip color="white" styles={{ body: { padding: 0 } }} title={getSiteNamesWithKwh()}>{totalKwh}</Tooltip></Title></Spin>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        {/* Right Side - Full Height */}
                        <Col {...responsiveColCards} className="right-full-height">
                            <Card size='small' className="full-height-card">
                                <Spin size="large" spinning={(permittedCustomersLoading || loadingGenDetails || (customerSites.length === 0 && customerSitesLoading)) && !loadingLength}><MapsGlobal loading={loadingGenDetails} enableControls={false} main={false} loc={detailedGeneratorData.map(s => s.site_details)} /></Spin>
                            </Card>
                        </Col>
                    </Row>
                    <TableDashboard />
                </div>
            }
        </div >
    );
}

export default GlobalDashboard3;
