import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchdetailedSummaryCharger, fetchBPLiveDataForCustomer, fetchBPLiveDataForCustomerFactory, fetchBPLiveDataForCustomerForOneSite, fetchdetailedSummaryChargerForSite, fetchMaintRegistry, MaintenanceHistory, fetchMaintenanceThreshold, MaintenanceThresholdRecord, MaintenanceThreshold2, fetchMaintenanceTickets, TicketHistory } from '../services/dashboardService';
import { detailedChargerDataInterface, SessionData } from '../types/chargerTypes';
import { detailedGeneratorData, LiveDataItem } from '../types/powerTypes';
import _, { isEqual } from 'lodash';

// Define the ChargerState type
interface ChargerState {
    detailedChargerData: detailedChargerDataInterface;
    loadingDetailedChargerData: boolean;
    detailedChargerDataForSite: detailedChargerDataInterface;
    chargersId: number[];
    loadingDetailedChargerDataForSite: boolean;
    error: string | null;
    responses: detailedGeneratorData[][];
    maintenanceResponses: MaintenanceThreshold2[];
    maintenances: MaintenanceThresholdRecord[]
    detailedGeneratorData: detailedGeneratorData[];
    detailedGeneratorDataFactory: detailedGeneratorData[];
    detailedGeneratorDataForSite: detailedGeneratorData[];
    loadingGenDetails: boolean;
    loadingGenDetailsFactory: boolean;
    loadingGenDetailsForSite: boolean;
    errorGenDetails: string | null;
    errorGenDetailsFactory: string | null;
    errorGenDetailsForSite: string | null;
    loadingMaintenanceHistory: boolean;
    loadingMaintenaceTickets: boolean;
    loadingMaintenance: boolean;
    maintenanceHistory: MaintenanceHistory[];
    maintenanceTicket: TicketHistory[];
    maintenanceData: MaintenanceThresholdRecord[];
    errorMaintenance: string | null;
    errorMaintenanceHistory: string | null;
    errorMaintenaceTicket: string | null;
    loading: boolean,
    loadingData: boolean
}

// Initialize the state
const initialState: ChargerState = {
    detailedChargerData: { session: [], connector: [], all_connected_chargers: [] },
    loadingDetailedChargerData: false,
    loadingGenDetailsFactory: false,
    maintenanceData: [],
    errorMaintenance: '',
    loadingMaintenance: false,
    maintenanceResponses: [],
    detailedGeneratorDataFactory: [],
    errorGenDetailsFactory: '',
    error: '',
    chargersId: [],
    loadingMaintenanceHistory: false,
    maintenances: [],
    maintenanceHistory: [],
    maintenanceTicket: [],
    errorMaintenaceTicket: '',
    loadingMaintenaceTickets: false,
    errorMaintenanceHistory: '',
    detailedGeneratorDataForSite: [],
    errorGenDetailsForSite: '',
    detailedGeneratorData: [],
    responses: [],
    loadingGenDetailsForSite: false,
    loadingGenDetails: false,
    errorGenDetails: '',
    detailedChargerDataForSite: { session: [], connector: [], all_connected_chargers: [] },
    loadingDetailedChargerDataForSite: false,
    loading: false,
    loadingData: false
};

// Create the slice
const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        clearDashboardData(state) {
            state.chargersId = [];
            state.detailedChargerData = { session: [], connector: [], all_connected_chargers: [] };
            state.detailedChargerDataForSite = { session: [], connector: [], all_connected_chargers: [] };
            state.detailedGeneratorData = [];
            state.detailedGeneratorDataForSite = [];
            state.detailedGeneratorDataFactory = [];
            state.error = null;
            state.errorGenDetails = null;
            state.errorGenDetailsForSite = null;
            state.errorGenDetailsFactory = null;
            state.loadingDetailedChargerData = false;
            state.loadingDetailedChargerDataForSite = false;
            state.loadingGenDetails = false;
            state.loadingGenDetailsForSite = false;
            state.loadingGenDetailsFactory = false;
        },
        clearGeneralData(state) {
            state.detailedChargerDataForSite = { session: [], connector: [], all_connected_chargers: [] };
            state.detailedGeneratorDataForSite = [];
        },
        setResponses(state, action) {
            state.responses = [...state.responses, action.payload];
        },
        clearResponses(state) {
            state.responses = [];
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        setLoadingData(state, action: PayloadAction<boolean>) {
            state.loadingData = action.payload
        },
        updateCustomerResponse(
            state,
            action: PayloadAction<{ data: detailedGeneratorData[]; customer: string }>
        ) {
            const customerDataMap = new Map(
                state.responses.map((group, index) => [group[0]?.requested_schema, index])
            );

            const index = customerDataMap.get(action.payload.customer);

            if (index !== undefined) {
                if (!isEqual(state.responses[index], action.payload.data)) {
                    state.responses[index] = action.payload.data;
                }
            } else {
                state.responses.push(action.payload.data);
            }
        },
        updateMaintenanceThreshold(
            state,
            action: PayloadAction<{ data: MaintenanceThresholdRecord[]; customer: string }>
        ) {
            // Find if maintenance data for this customer already exists
            const existingIndex = state.maintenanceResponses.findIndex(
                (entry: any) => entry.customer === action.payload.customer
            );

            if (existingIndex !== -1) {
                // Update existing entry if data has changed
                if (!isEqual(state.maintenanceResponses[existingIndex].data, action.payload.data)) {
                    state.maintenanceResponses[existingIndex].data = action.payload.data;
                }
            } else {
                // Add new customer maintenance data
                state.maintenanceResponses.push({
                    customer: action.payload.customer,
                    data: action.payload.data,
                });
            }
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchdetailedSummaryCharger.pending, (state) => {
                state.loadingDetailedChargerData = true; // Loading starts
                state.error = null; // Reset error
            })
            // Fulfilled: Set loading to false and store the fetched data
            .addCase(fetchdetailedSummaryCharger.fulfilled, (state, action: PayloadAction<detailedChargerDataInterface>) => {
                state.loadingDetailedChargerData = false; // Loading ends
                state.detailedChargerData = action.payload; // Store session data
            })
            // Rejected: Set loading to false and store the error message
            .addCase(fetchdetailedSummaryCharger.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
                state.error = action.payload || 'Failed to fetch session data'; // Store the error
                if (action.payload && (action.payload === 'Request canceled')) {
                    state.loadingDetailedChargerData = true;
                }
                else state.loadingDetailedChargerData = false;
            }).addCase(fetchdetailedSummaryChargerForSite.pending, (state) => {
                state.loadingDetailedChargerDataForSite = true; // Loading starts
                state.error = null; // Reset error
            })
            // Fulfilled: Set loading to false and store the fetched data
            .addCase(fetchdetailedSummaryChargerForSite.fulfilled, (state, action: PayloadAction<detailedChargerDataInterface>) => {
                state.loadingDetailedChargerDataForSite = false; // Loading ends
                state.detailedChargerDataForSite = action.payload; // Store session data
                state.chargersId = _.compact(_.map(action.payload.all_connected_chargers, "id"));
            })
            // Rejected: Set loading to false and store the error message
            .addCase(fetchdetailedSummaryChargerForSite.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
                state.error = action.payload || 'Failed to fetch session data'; // Store the error
                if (action.payload && (action.payload === 'Request canceled')) {
                    state.loadingDetailedChargerDataForSite = true;
                }
                else state.loadingDetailedChargerDataForSite = false;
            }).addCase(fetchBPLiveDataForCustomer.pending, (state) => {
                state.loadingGenDetails = true; // Loading starts
                state.errorGenDetails = null; // Reset error
            }).addCase(fetchBPLiveDataForCustomer.fulfilled, (state, action: PayloadAction<detailedGeneratorData[]>) => {
                state.loadingGenDetails = false; // Loading ends
                state.detailedGeneratorData = action.payload; // Store session data
            }).addCase(fetchBPLiveDataForCustomer.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
                state.errorGenDetails = action.payload as string;
            }).addCase(fetchBPLiveDataForCustomerForOneSite.pending, (state) => {
                state.loadingGenDetailsForSite = true; // Loading starts
                state.errorGenDetailsForSite = null; // Reset error
            }).addCase(fetchBPLiveDataForCustomerForOneSite.fulfilled, (state, action: PayloadAction<detailedGeneratorData[]>) => {
                state.loadingGenDetailsForSite = false; // Loading ends
                state.detailedGeneratorDataForSite = action.payload; // Store session data
            }).addCase(fetchBPLiveDataForCustomerForOneSite.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
            })
            .addCase(fetchBPLiveDataForCustomerFactory.pending, (state) => {
                state.loadingGenDetailsFactory = true; // Loading starts
                state.errorGenDetailsFactory = null; // Reset error
            }).addCase(fetchBPLiveDataForCustomerFactory.fulfilled, (state, action: PayloadAction<detailedGeneratorData[]>) => {
                state.loadingGenDetailsFactory = false; // Loading ends
                state.detailedGeneratorDataFactory = action.payload; // Store session data
            }).addCase(fetchBPLiveDataForCustomerFactory.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
                state.errorGenDetailsFactory = action.payload as string;
            }).addCase(fetchMaintRegistry.pending, (state) => {
                state.loadingMaintenanceHistory = true; // Loading starts
            }).addCase(fetchMaintRegistry.fulfilled, (state, action: PayloadAction<any>) => {
                state.loadingMaintenanceHistory = false; // Loading ends
                state.maintenanceHistory = action.payload; // Store session data
            }).addCase(fetchMaintRegistry.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
                state.errorMaintenanceHistory = action.payload as string;
            }).addCase(fetchMaintenanceTickets.pending, (state) => {
                state.loadingMaintenaceTickets = true; // Loading starts
            }).addCase(fetchMaintenanceTickets.fulfilled, (state, action: PayloadAction<any>) => {
                state.loadingMaintenaceTickets = false; // Loading ends
                state.maintenanceTicket = action.payload; // Store session data
            }).addCase(fetchMaintenanceTickets.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
                state.errorMaintenaceTicket = action.payload as string;
            }).addCase(fetchMaintenanceThreshold.pending, (state) => {
                state.loadingMaintenance = true; // Loading starts
            }).addCase(fetchMaintenanceThreshold.fulfilled, (state, action: PayloadAction<any>) => {
                state.loadingMaintenance = false; // Loading ends
                state.maintenanceData = action.payload; // Store session data
            }).addCase(fetchMaintenanceThreshold.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
                state.errorMaintenance = action.payload as string;
            })
    },
});

export const { updateMaintenanceThreshold, updateCustomerResponse, clearDashboardData, clearGeneralData, setResponses, clearResponses, setLoading, setLoadingData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
