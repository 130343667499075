import { Button, Checkbox, CheckboxOptionType, Col, Empty, Input, Popover, Row, Space, Spin, Table, Tooltip } from "antd";
import './General.css';  // Assuming you add the CSS here
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import '../dashboard/Dashboard.css';
import { EyeInvisibleOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { clearUserInfo, setSelectedCustomerName, setSelectedSite, setSelectedTimezone } from "../../redux/slices/customerSlice";
import { FUEL, GENERAL, POWER_GENERATION, red, blue, yellow, green, grey, faults, alarms, MAINTENANCE_SCREEN } from "../../utils/constants";
import _, { set } from "lodash";
import MainIconTemp3 from "./MainIconTemp3";
import dayjs from "dayjs";
import { TableInterface } from "../../redux/types/dashboardTypes";
import { ColumnsType } from "antd/es/table";
import { clearPowerState, setSelectedGeneratorId, setSelectedSourceId } from "../../redux/slices/powerSlice";
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import { toast } from "react-toastify";
import { clearChargerData } from "../../redux/slices/chargerSlice";
import { createStyles } from 'antd-style';
import { clearDashboardData } from "../../redux/slices/dashboardSlice";
// Extend Day.js with the duration plugin
dayjs.extend(duration);
dayjs.extend(timezone);

const useStyle = createStyles(({ css, token }) => {
    return {
        customTable: css`
            .ant-table {
                .ant-table-container {
                    .ant-table-body,
                    .ant-table-content {
                        scrollbar-width: thin;
                        scrollbar-color: ${token.colorBorder} transparent;
                        scrollbar-gutter: stable;

                        &::-webkit-scrollbar {
                            width: 6px;
                            height: 6px;
                        }

                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: ${token.colorBorder};
                            border-radius: ${token.borderRadius}px;
                        }
                    }
                }
            }
        `
    };
});

interface ColorCircleProps {
    color: string;  // The color for the circle background
    value: number;  // The number or text to display in the center
}

const TableDashboardFactory = ({ prodClicked, faultClicked, healthyClicked, setCheckedList, checkedList }: { prodClicked: boolean, setProdClicked: any, faultClicked: boolean, setFaultClicked: any, healthyClicked: boolean, setHealthyClicked: any, setCheckedList: any, checkedList: any }) => {
    const { permittedCustomers, permittedCustomersLoading, selectedCustomerName, theme: iconThemeFromRedux, selectedTimezone, selectedTimezoneAbb } = useSelector((state: RootState) => state.userInfo);
    const { detailedChargerData, responses, loading, loadingData, maintenanceData: maintenanceResponse, maintenanceResponses } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useDispatch<AppDispatch>();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filteredData, setFilteredData] = useState<TableInterface[]>([]);
    const [generatorData, setGeneratorData] = useState<TableInterface[]>([]);
    const [open, setOpen] = useState(false);
    const [batteryFilter, setBatteryFilter] = useState<any>([]);
    const [genFilter, setGenFilter] = useState<any>([]);
    const [filter, setFilter] = useState<any>([]);
    const navigate = useNavigate();
    const [iconTheme, setIconThemeLocal] = useState<number>(iconThemeFromRedux);
    const notify = () => toast(<b>This feature is not available for this site</b>, { type: 'warning' });
    const tableRef = useRef<any>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);


    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setGenFilter((prevGenFilter: any) => {
            if (prodClicked) {
                // Add 'running' if it's not already present
                return prevGenFilter.includes('running') ? prevGenFilter : [...prevGenFilter, 'running'];
            } else {
                // Remove 'running' if present
                return prevGenFilter.filter((filter: any) => filter !== 'running');
            }
        });
    }, [prodClicked]);

    useEffect(() => {
        setGenFilter((prevGenFilter: any) => {
            if (faultClicked) {
                // Add 'fault' if it's not already present
                return prevGenFilter.includes('fault') ? prevGenFilter : [...prevGenFilter, 'fault'];
            } else {
                // Remove 'fault' if present
                return prevGenFilter.filter((filter: any) => filter !== 'fault');
            }
        })
    }, [faultClicked])

    useEffect(() => {
        setGenFilter((prevGenFilter: any) => {
            if (healthyClicked) {
                // Add 'ableToRun' if it's not already present
                return prevGenFilter.includes('ableToRun') ? prevGenFilter : [...prevGenFilter, 'ableToRun'];
            } else {
                // Remove 'ableToRun' if present
                return prevGenFilter.filter((filter: any) => filter !== 'ableToRun');
            }
        })
    }, [healthyClicked])



    useEffect(() => {
        setIconThemeLocal(iconThemeFromRedux);
    }, [iconThemeFromRedux])



    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    useEffect(() => {
        if (!responses?.length) {
            setFilteredData([]);
            return;
        }

        const flattenedResponse = responses.flat();

        const newData = flattenedResponse
            .map((site) => {
                return {
                    id: site.site_details?.id ?? 0,
                    name: site.site_details?.name ?? '',
                    description: site.site_details?.description ?? '',
                    location: {
                        source: site.site_details?.location?.source ?? '',
                        latitude: site.site_details?.location?.latitude ?? 0,
                        longitude: site.site_details?.location?.longitude ?? 0,
                        last_update_at: site.site_details?.location?.last_update_at ?? '',
                        status: site.site_details?.location?.status ?? 0,
                    },
                    victron_link: site.site_details?.victron_link ?? '',
                    communication_link: site.site_details?.communication_link ?? '',
                    preferences: {
                        ...site.site_details?.preferences,
                        present_components: site.site_details?.preferences?.present_components ?? [],
                    },
                    generatorStatus: flattenedResponse
                        .filter(response =>
                            response.site_details?.name === site.site_details?.name &&
                            response.requested_schema === site.requested_schema)
                        .map(gen => ({
                            ...gen,
                            equipment_data: gen.equipment_data?.filter(items => {
                                if (!items?.data?.length) return false;

                                // Filter out EV Chargers and BCH-Fuel
                                const genName = items?.data?.[0]?.equipment_name;
                                if (['EV Charger', 'EV_Charger'].includes(genName) ||
                                    items?.data?.[0]?.source_id === 'BCH-Fuel') {
                                    return false;
                                }

                                // If no filters are selected, only exclude EV Chargers and BCH-Fuel
                                if (!prodClicked && !healthyClicked && !faultClicked) {
                                    return true;
                                }

                                // Get status indicators
                                const notliveData = items.LiveStatus !== 'live';
                                const runningStatusVal = items?.data?.find(item => item.value_name === 'Volts AB')?.value;
                                const runningStatus = runningStatusVal !== undefined && runningStatusVal > 100;

                                const faultStatuses = items?.data?.filter(item =>
                                    faults.includes(item.value_name) && (item.value === 1));
                                const alarmStatuses = items?.data?.filter(item =>
                                    alarms.includes(item.value_name) && (item.value === 1));
                                const faultStatusesNegative = items?.data?.some(item =>
                                    item?.value_name && faults.includes(item.value_name) && item.value === -99);
                                const engineHourCheck = items?.data?.some(item =>
                                    item?.value_name === 'Engine Hours' && item.value < 0);

                                // Handle combinations of filters
                                const matchesProd = prodClicked ?
                                    (!notliveData && !engineHourCheck && !faultStatusesNegative && runningStatus) : false;
                                const matchesHealthy = healthyClicked ?
                                    (!notliveData && !engineHourCheck && !faultStatusesNegative && !runningStatus &&
                                        faultStatuses.length === 0 &&
                                        alarmStatuses.filter(alarm =>
                                            !['Common Alarm', 'Warning Alarm'].includes(alarm.value_name)).length === 0) : false;
                                const matchesFault = faultClicked ?
                                    (!notliveData && !engineHourCheck && !faultStatusesNegative &&
                                        faultStatuses.length > 0) : false;

                                // Return true if the equipment matches ANY of the selected filters
                                return matchesProd || matchesHealthy || matchesFault;
                            })
                        })),
                    chargerList: [],
                    requested_schema: site?.requested_schema ?? '',
                    chargerStatus: [],
                };
            })

        requestAnimationFrame(() => {
            setFilteredData(newData);
        });

    }, [responses, detailedChargerData, prodClicked, faultClicked, healthyClicked]); // Include genFilter as a dependency to reapply filtering




    useEffect(() => {
        if (searchQuery.trim() === '' && filteredData.length > 0) {
            setGeneratorData(filteredData); // Show all if searchQuery is empty
        } else if (searchQuery.trim() !== '' && filteredData.length > 0) {
            const lowercasedQuery = searchQuery.toLowerCase();

            const filtered = filteredData.filter((site) => {
                const schemaName = site.generatorStatus[0].requested_schema;
                const siteName = site.name;
                // Adjust this to include the fields you want to search through
                return (
                    schemaName?.toLowerCase().includes(lowercasedQuery) || siteName?.toLowerCase().includes(lowercasedQuery)
                );
            });

            setGeneratorData(filtered);
        }
    }, [searchQuery, filteredData]);

    const ColorCircle: React.FC<ColorCircleProps> = ({ color, value }) => {
        return (
            <svg width="35" height="35" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                {/* Circle with dynamic color */}
                <circle cx="17.5" cy="17.5" r="17.5" fill={color} />
                {/* Number/Text in the center */}
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="central"
                    textAnchor="middle"
                    pointerEvents={'all'}
                    fill="white"
                    fontSize="16"
                    fontWeight={400}
                    fontFamily="Arial, sans-serif"
                    dy="0.05em"
                    dx={value > 99 ? '-0.1em' : ''}
                >
                    {value === -123 ? '' : value < 0 ? 'N/A' : value}
                </text>
            </svg>
        );
    };

    const handleGeneratorClick = (name: string, customer: string, eqpId: number, genId: number, sourceId: string, present_components: string[]) => {
        const allow = present_components.includes(POWER_GENERATION);
        if (allow) {
            dispatch(setSelectedGeneratorId(genId));
            dispatch(setSelectedSourceId(sourceId));
            navigate(`/${customer}/${name}/${POWER_GENERATION}/${eqpId}`);
        }
        else {
            notify();
        }
    }

    const handleFuelClick = (name: string, customer: string, eqpId: number, present_components: string[]) => {
        const allow = present_components.includes(FUEL);
        if (allow) {
            navigate(`/${customer}/${name}/${FUEL}/${eqpId}`);
        }
        else {
            notify();
        }
    }

    const columns: ColumnsType<TableInterface> = [
        {
            title: 'CUSTOMER',
            dataIndex: 'customer',
            filteredValue: filter[0],
            // defaultSortOrder: 'ascend',
            key: '0',
            filters: permittedCustomers.map((customer) => ({ text: customer.customer, value: customer.customer })),
            onFilter: (value, record) => record.generatorStatus[0].requested_schema.indexOf(value as string) === 0,
            sorter: (a, b) => a.generatorStatus[0].requested_schema.localeCompare(b.generatorStatus[0].requested_schema),
            render: (text: any, record) => <Link to={`/${record.generatorStatus[0].requested_schema}`}>{permittedCustomers.filter((customer) => customer.customer === record.generatorStatus[0].requested_schema)[0].customer_logo?.length > 0 ? <img style={{ width: '90px', height: '40px' }} src={permittedCustomers.filter((customer) => customer.customer === record.generatorStatus[0].requested_schema)[0].customer_logo} alt="logo" /> : record.generatorStatus[0].requested_schema}</Link>,
        },
        {
            title: 'SITE',
            filteredValue: filter[1],
            dataIndex: 'location',
            filters: filteredData.map((site) => ({ text: site.name, value: site.name })),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.name.startsWith(value as string),
            key: '1',
            render: (text: any, record) => <Link to={`/${record.generatorStatus[0].requested_schema}/${record.name}/${GENERAL}`}>{`${record.name}`}</Link>,
        },
        {
            title: 'FUEL (%)',
            filteredValue: filter[2],
            key: '3',
            filters: [
                { text: 'Critical (< 25%)', value: 'critical' },
                { text: 'Low (25-75%)', value: 'low' },
                { text: 'Good (> 75%)', value: 'good' },
                { text: 'N/A', value: 'na' }
            ],
            onFilter: (value, record) => {
                return record.generatorStatus.some(gen => {
                    let sumFuel = 0;
                    gen.equipment_data.forEach((items) => {
                        const genName = items.data[0].equipment_name;
                        if (['EV Charger', 'EV_Charger'].includes(genName) ||
                            (items.data[0].source_id !== 'BCH-Fuel' && selectedCustomerName === 'bchydro')) return;

                        const fuelLevelRegex = /Fuel Level(\s*\(.*\))?/i;
                        const fuelValue: any = items.data.find((item) =>
                            (fuelLevelRegex.test(item.value_name) && item.source_id === 'BCH-Fuel') ||
                            (fuelLevelRegex.test(item.value_name) && item.source_id !== 'BCH-Fuel')
                        )?.value || 0;
                        sumFuel += parseFloat(fuelValue) || 0;
                    });

                    switch (value) {
                        case 'critical':
                            return sumFuel > 0 && sumFuel < 25;
                        case 'low':
                            return sumFuel >= 25 && sumFuel <= 75;
                        case 'good':
                            return sumFuel > 75;
                        case 'na':
                            return sumFuel === 0;
                        default:
                            return false;
                    }
                });
            },
            dataIndex: 'fuel',
            render: (text: any, record) => {
                return (
                    <Space size="small">
                        {record.generatorStatus.map((gen, key) => {
                            let sumFuel = 0;
                            let timeStamp = null;
                            // let notliveData = false;
                            gen.equipment_data.forEach((items) => {
                                const genName = items.data[0].equipment_name;
                                // notliveData = items?.LiveStatus !== 'live';

                                if (['EV Charger', 'EV_Charger'].includes(genName) ||
                                    (items.data[0].source_id !== 'BCH-Fuel' && selectedCustomerName === 'bchydro')) return;

                                const fuelLevelRegex = /Fuel Level(\s*\(.*\))?/i;


                                // Simplified fuelValue calculation
                                const fuelValue: any = items.data.find((item) =>
                                    (fuelLevelRegex.test(item.value_name) && item.source_id === 'BCH-Fuel') ||
                                    (fuelLevelRegex.test(item.value_name) && item.source_id !== 'BCH-Fuel')
                                )?.value || 0;

                                timeStamp = items.data.find((item) =>
                                    (fuelLevelRegex.test(item.value_name) && item.source_id === 'BCH-Fuel') ||
                                    (fuelLevelRegex.test(item.value_name) && item.source_id !== 'BCH-Fuel')
                                )?.value_datetime || null;
                                // Parse fuelValue to ensure it's a number
                                sumFuel += parseFloat(fuelValue) || 0;
                            });

                            if (sumFuel === 0) return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : <div key={key}>N/A</div>;

                            timeStamp = timeStamp ? selectedTimezone === 'UTC' ? dayjs.utc(timeStamp).format('YYYY-MM-DD HH:mm:ss') : dayjs(timeStamp).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss') : null;

                            const tableData = [
                                {
                                    key: key,
                                    lastUpdated: timeStamp,
                                },
                            ];

                            const columns = [
                                { title: 'Last Updated ' + `(${selectedTimezoneAbb})`, dataIndex: 'lastUpdated', key: 'lastUpdated' },
                            ];

                            return (
                                <div key={key}>
                                    {iconTheme === 0 ?
                                        <div key={gen.site_id} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <div style={{ cursor: 'pointer' }}
                                                onClick={() => handleFuelClick(record.name, record.requested_schema, gen.equipment_data[0].equipment_id, gen.site_details?.preferences?.present_components)}
                                            >
                                                <Tooltip color="white" styles={{ body: { padding: 0 } }}
                                                    title={<Table
                                                        columns={columns}
                                                        dataSource={tableData}
                                                        pagination={false}
                                                        size="small"
                                                        bordered
                                                    />} > <ColorCircle color={sumFuel < 25 ? red : sumFuel <= 75 ? yellow : green} value={_.round(sumFuel < 0 ? 0 : sumFuel, 0)} /> </Tooltip>
                                            </div>
                                        </div> :
                                        <Tooltip color="white" styles={{ body: { padding: 0 } }}
                                            title={<Table
                                                columns={columns}
                                                dataSource={tableData}
                                                pagination={false}
                                                size="small"
                                                bordered
                                            />} >
                                            <div key={gen.site_id} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                {sumFuel < 25 ? <MainIconTemp3 fillColor={red} /> :
                                                    sumFuel <= 75 ? <MainIconTemp3 fillColor={yellow} /> :
                                                        <MainIconTemp3 fillColor={green} />}
                                                <div onClick={() => handleFuelClick(record.name, record.requested_schema, gen.equipment_data[0].equipment_id, gen.site_details?.preferences?.present_components)}
                                                >{_.round(sumFuel, 2)}</div>
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            );
                        })}
                    </Space>
                );
            },
        },

        {
            title: 'GENERATORS',
            key: '4',
            dataIndex: 'generators',
            filters: [
                { text: 'Running', value: 'running' },
                { text: 'Able to Run', value: 'ableToRun' },
                { text: 'Fault', value: 'fault' },
                { text: 'Alarm', value: 'alarm' },
                { text: 'Offline/Unknown', value: 'offline' },
                { text: 'N/A', value: 'na' }
            ],
            filteredValue: genFilter,
            onFilter: (value, record) => {

                // Special handling for N/A case
                if (value === 'na') {
                    return !record.generatorStatus?.some((gen) =>
                        gen.equipment_data?.some((items) => {
                            const genName = items?.data?.[0]?.equipment_name;
                            return !['EV Charger', 'EV_Charger'].includes(genName) &&
                                items?.data?.[0]?.source_id !== 'BCH-Fuel';
                        })
                    );
                }

                return record.generatorStatus?.some((gen) =>
                    gen.equipment_data?.some((items) => {
                        const genName = items?.data?.[0]?.equipment_name;
                        if (['EV Charger', 'EV_Charger'].includes(genName) ||
                            items?.data?.[0]?.source_id === 'BCH-Fuel') {
                            return false;
                        }

                        const notliveData = items?.LiveStatus !== 'live';
                        const runningStatusVal = items?.data?.find(item => item.value_name === 'Volts AB')?.value;
                        const runningStatus = runningStatusVal !== undefined && runningStatusVal > 100;

                        const faultStatuses = items?.data?.filter(item =>
                            faults.includes(item.value_name) && (item.value === 1));
                        const alarmStatuses = items?.data?.filter(item =>
                            alarms.includes(item.value_name) && (item.value === 1))
                            .map(item => item.value_name);
                        const faultStatusesNegative = items?.data?.some(item =>
                            item?.value_name && faults.includes(item.value_name) &&
                            item.value === -99);
                        const engineHourCheck = items?.data?.some(item =>
                            item?.value_name === 'Engine Hours' && item.value < 0);

                        switch (value) {
                            case 'running':
                                return !notliveData && !engineHourCheck &&
                                    !faultStatusesNegative && runningStatus;
                            case 'ableToRun':
                                return !notliveData && !engineHourCheck &&
                                    !faultStatusesNegative && !runningStatus &&
                                    faultStatuses.length === 0 &&
                                    alarmStatuses.filter(alarmName =>
                                        !['Common Alarm', 'Warning Alarm'].includes(alarmName)
                                    ).length === 0;
                            case 'fault':
                                return !notliveData && !engineHourCheck &&
                                    !faultStatusesNegative && faultStatuses.length > 0;
                            case 'alarm':
                                return !notliveData && !engineHourCheck &&
                                    !faultStatusesNegative && faultStatuses.length === 0 &&
                                    alarmStatuses.filter(alarmName =>
                                        !['Common Alarm', 'Warning Alarm'].includes(alarmName)
                                    ).length > 0;
                            case 'offline':
                                return notliveData || engineHourCheck || faultStatusesNegative;
                            default:
                                return false;
                        }
                    })
                );
            },
            render: (text, record) => {
                const hasGenerators = record.generatorStatus?.some((gen) =>
                    gen.equipment_data?.some((items) => {
                        const genName = items?.data?.[0]?.equipment_name;
                        return !['EV Charger', 'EV_Charger'].includes(genName) &&
                            items?.data?.[0]?.source_id !== 'BCH-Fuel';
                    })
                );

                if (!hasGenerators) {
                    return iconTheme === 0 ?
                        <ColorCircle color={grey} value={-1} /> :
                        <div key={record.id}>N/A</div>;
                }

                // Get all generators that match the current filter
                const allGenerators = record.generatorStatus?.flatMap((gen) =>
                    gen.equipment_data?.flatMap((items) => {
                        const genName = items?.data?.[0]?.equipment_name;
                        if (!genName || ['EV Charger', 'EV_Charger'].includes(genName) ||
                            items?.data?.[0]?.source_id === 'BCH-Fuel') {
                            return [];
                        }

                        // Apply current filter if it exists
                        if (genFilter) {
                            const notliveData = items?.LiveStatus !== 'live';
                            const runningStatusVal = items?.data?.find(item => item.value_name === 'Volts AB')?.value;
                            const runningStatus = runningStatusVal !== undefined && runningStatusVal > 100;

                            const faultStatuses = items?.data?.filter(item =>
                                faults.includes(item.value_name) && (item.value === 1));
                            const alarmStatuses = items?.data?.filter(item =>
                                alarms.includes(item.value_name) && (item.value === 1))
                                .map(item => item.value_name);
                            const faultStatusesNegative = items?.data?.some(item =>
                                item?.value_name && faults.includes(item.value_name) &&
                                item.value === -99);
                            const engineHourCheck = items?.data?.some(item =>
                                item?.value_name === 'Engine Hours' && item.value < 0);

                            const matchesFilter = (() => {
                                if (!Array.isArray(genFilter) || genFilter.length === 0) {
                                    return true;  // Default behavior when genFilter is empty or not an array
                                }

                                return genFilter.some(filter => {
                                    switch (filter) {
                                        case 'running':
                                            return !notliveData && !engineHourCheck &&
                                                !faultStatusesNegative && runningStatus;
                                        case 'ableToRun':
                                            return !notliveData && !engineHourCheck &&
                                                !faultStatusesNegative && !runningStatus &&
                                                faultStatuses.length === 0 &&
                                                alarmStatuses.filter(alarmName =>
                                                    !['Common Alarm', 'Warning Alarm'].includes(alarmName)
                                                ).length === 0;
                                        case 'fault':
                                            return !notliveData && !engineHourCheck &&
                                                !faultStatusesNegative && faultStatuses.length > 0;
                                        case 'alarm':
                                            return !notliveData && !engineHourCheck &&
                                                !faultStatusesNegative && faultStatuses.length === 0 &&
                                                alarmStatuses.filter(alarmName =>
                                                    !['Common Alarm', 'Warning Alarm'].includes(alarmName)
                                                ).length > 0;
                                        case 'offline':
                                            return notliveData || engineHourCheck || faultStatusesNegative;
                                        default:
                                            return false;
                                    }
                                });
                            })();

                            if (!matchesFilter) return []
                        }

                        return [{
                            gen,
                            items,
                            genName,
                            key: `${gen.site_id}-${items.equipment_id}`
                        }];
                    }).filter(Boolean) || []
                ).filter(Boolean);

                // Sort generators by name
                allGenerators.sort((a, b) => a.genName.localeCompare(b.genName));

                return (
                    <Space size="small">
                        {allGenerators.map((genData, index) => {
                            const { gen, items, genName, key } = genData;
                            const displayIndex = index + 1;

                            const notliveData = items?.LiveStatus !== 'live';
                            const runningStatusVal = items?.data?.find(item => item.value_name === 'Volts AB')?.value;
                            const runningStatus = runningStatusVal !== undefined && runningStatusVal > 100;

                            const faultStatuses = items?.data
                                ?.filter(item => faults.includes(item.value_name) &&
                                    (item.value === 1))
                                ?.map(item => item.value_name) || [];
                            const alarmStatuses = items?.data
                                ?.filter(item => alarms.includes(item.value_name) &&
                                    (item.value === 1))
                                ?.map(item => item.value_name) || [];
                            const faultStatusesNegative = items?.data?.some(item =>
                                item?.value_name && faults.includes(item.value_name) &&
                                item.value === -99);
                            const engineHourCheck = items?.data?.some(item =>
                                item?.value_name === 'Engine Hours' && item.value < 0);

                            const lastUpdated = items?.data?.[0]?.value_datetime ?
                                selectedTimezone === 'UTC' ?
                                    dayjs.utc(items?.data?.[0]?.value_datetime)
                                        .format('YYYY-MM-DD HH:mm:ss') :
                                    dayjs(items?.data?.[0]?.value_datetime)
                                        .tz(selectedTimezone)
                                        .format('YYYY-MM-DD HH:mm:ss') :
                                null;

                            const color = notliveData || engineHourCheck || faultStatusesNegative
                                ? grey
                                : runningStatus
                                    ? blue
                                    : faultStatuses.length > 0
                                        ? red
                                        : alarmStatuses.filter(alarm =>
                                            !['Common Alarm', 'Warning Alarm'].includes(alarm)
                                        ).length > 0
                                            ? yellow
                                            : green;

                            const tableData = [{
                                key: displayIndex,
                                name: genName,
                                status: notliveData || faultStatusesNegative || engineHourCheck
                                    ? 'Unknown/Offline'
                                    : runningStatus
                                        ? 'Running'
                                        : faultStatuses.length > 0
                                            ? 'Fault'
                                            : alarmStatuses.filter(alarm =>
                                                !['Common Alarm', 'Warning Alarm'].includes(alarm)
                                            ).length > 0
                                                ? 'Alarm'
                                                : 'Able to run',
                                faults: faultStatuses.length === 0
                                    ? 'None'
                                    : faultStatuses.join(', '),
                                alarms: alarmStatuses.length === 0
                                    ? 'None'
                                    : alarmStatuses.join(', '),
                                lastUpdated
                            }];

                            const columns = [
                                { title: 'Name', dataIndex: 'name', key: 'name' },
                                { title: 'Status', dataIndex: 'status', key: 'status' },
                                { title: 'Faults', dataIndex: 'faults', key: 'faults' },
                                { title: 'Alarms', dataIndex: 'alarms', key: 'alarms' },
                                {
                                    title: `Last updated (${selectedTimezoneAbb})`,
                                    dataIndex: 'lastUpdated',
                                    key: 'lastUpdated'
                                },
                            ];
                            return (
                                <Popover
                                    key={key}
                                    content={
                                        <Table
                                            columns={columns}
                                            dataSource={tableData}
                                            pagination={false}
                                            size="small"
                                            bordered
                                        />
                                    }
                                    color="white"
                                    styles={{ body: { padding: 0 } }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleGeneratorClick(
                                                record?.name,
                                                record.requested_schema,
                                                items?.equipment_id,
                                                items.data[0]?.equipment_id,
                                                items.data[0]?.source_id,
                                                gen.site_details?.preferences?.present_components
                                            )}
                                        >
                                            {iconTheme === 0 ? (
                                                <ColorCircle color={color} value={genName === 'Gen_01' ? 1 : genName === 'Gen_02' ? 2 : genName === 'Gen_03' ? 3 : displayIndex} />
                                            ) : (
                                                <div key={displayIndex} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '5px'
                                                }}>
                                                    <MainIconTemp3 fillColor={color} /> {genName}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Popover>
                            );
                        })}
                    </Space>
                );
            }
        }
        ,
        {
            title: 'MAINTENANCE',
            key: '5',
            dataIndex: 'maintenance',
            render: (_: any, record) => {
                const equipmentList = record.generatorStatus
                    ?.filter(dt => dt.site_id === record.id)
                    ?.flatMap((gen) => gen.equipment_data)
                    ?.filter((items) => {
                        const equipmentName = items?.data?.[0]?.equipment_name?.trim().toLowerCase();
                        const sourceId = items?.data?.[0]?.source_id;
                        // Exclude EV Charger variations and BCH-Fuel
                        return equipmentName &&
                            !['ev charger', 'ev_charger', 'evcharger'].includes(equipmentName) &&
                            sourceId !== 'BCH-Fuel';
                    })
                    ?.map((items) => ({
                        id: items?.data?.[0]?.equipment_id, // Use equipment_id
                        name: items?.data?.[0]?.equipment_name?.trim() // Keep name for tooltip
                    }))
                    ?.filter(item => item.id) // Ensure ID is present
                    ?.sort((a, b) => a.name.localeCompare(b.name)) || [];

                if (!equipmentList.length) {
                    return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : 'N/A';
                }

                return (
                    <Space size="small">
                        {equipmentList.map((equipment, index) => {
                            // Find maintenance data for this site & equipment ID
                            const maintenanceData = maintenanceResponses
                                ?.filter(item => item.customer === record.generatorStatus[0].requested_schema)
                                ?.flatMap(data => data.data)
                                ?.find(dt => dt.equipment.id === equipment.id); // Use equipment_id for matching

                            console.log('maintenanceData', maintenanceData);

                            if (!maintenanceData) {
                                return iconTheme === 0 ? (
                                    <ColorCircle key={index} color={grey} value={-1} />
                                ) : (
                                    <div key={index}>N/A</div>
                                );
                            }

                            const { current_engine_hours, last_maintenance_hours, maintenance_threshold_hours, alert_threshold } = maintenanceData;

                            const hoursSinceLastMaintenance = Math.round(current_engine_hours - last_maintenance_hours);
                            const alertThresholdValue = alert_threshold * maintenance_threshold_hours;

                            // Prevent division by zero
                            const remainingHours = maintenance_threshold_hours > 0
                                ? Math.max(0, maintenance_threshold_hours - hoursSinceLastMaintenance)
                                : -1;

                            const remainingPercentage = maintenance_threshold_hours > 0
                                ? Math.max(0, Math.round((remainingHours / maintenance_threshold_hours) * 100))
                                : -1;

                            const color = alertThresholdValue === 0
                                ? grey
                                : hoursSinceLastMaintenance >= alertThresholdValue
                                    ? red
                                    : green;

                            return (
                                <Tooltip
                                    key={index}
                                    title={
                                        <>
                                            <div><b>Equipment:</b> {equipment.name} (ID: {equipment.id})</div>
                                            <div><b>Last:</b> {last_maintenance_hours}, <b>Current:</b> {current_engine_hours}</div>
                                            <div><b>Threshold:</b> {alertThresholdValue}</div>
                                            <div><b>Remaining:</b> {remainingPercentage}% ({remainingHours}h)</div>
                                        </>
                                    }
                                >
                                    <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '5px' }} onClick={() => navigate(`/${record.generatorStatus[0].requested_schema}/${record.name}/${MAINTENANCE_SCREEN}`)}>
                                        {iconTheme === 0 ? (
                                            <ColorCircle color={color} value={remainingPercentage} />
                                        ) : (
                                            <>
                                                <MainIconTemp3 fillColor={color} /> {hoursSinceLastMaintenance}h ({remainingPercentage}%)
                                            </>
                                        )}
                                    </div>
                                </Tooltip>
                            );
                        })}
                    </Space>
                );
            },
        }
        ,
        {
            title: 'BATTERIES',
            key: '8',
            dataIndex: 'batteries',
            filteredValue: filter[5],
            filters: [
                { text: 'Good (≥12V)', value: 'good' },
                { text: 'Moderate (10-12V)', value: 'moderate' },
                { text: 'Low (<10V)', value: 'low' },
                { text: 'No Voltage', value: 'noVoltage' },
                { text: 'Offline/Unknown', value: 'offline' },
                { text: 'N/A', value: 'na' },
            ],
            onFilter: (value, record) => {
                // Helper function to check if equipment is valid
                const isValidEquipment = (equipment: any) => {
                    const genName = equipment?.data?.[0]?.equipment_name;
                    return !['EV Charger', 'EV_Charger'].includes(genName) &&
                        equipment?.data?.[0]?.source_id !== 'BCH-Fuel';
                };

                // Handle empty or invalid generator status
                if (!record.generatorStatus?.length) {
                    return value === 'na';
                }

                // Get all valid equipment
                const validEquipment = record.generatorStatus
                    .flatMap(gen => gen.equipment_data?.filter(isValidEquipment) || []);

                // Handle N/A case
                if (value === 'na') {
                    return validEquipment.length === 0;
                }

                // Check equipment against filter criteria
                return validEquipment.some(equipment => {
                    const notliveData = equipment.LiveStatus !== 'live';
                    const batteryData = equipment.data?.find(item => item.value_name === 'Battery Voltage');
                    const voltage = batteryData?.value;

                    switch (value) {
                        case 'good':
                            return !notliveData && voltage != null && voltage >= 12;
                        case 'moderate':
                            return !notliveData && voltage != null && voltage >= 10 && voltage < 12;
                        case 'low':
                            return !notliveData && voltage != null && voltage < 10 && voltage >= 0;
                        case 'noVoltage':
                            return !notliveData && (voltage === 0 || voltage == null);
                        case 'offline':
                            return notliveData || (voltage && voltage < 0);
                        default:
                            return false;
                    }
                });
            },
            render: (text, record) => {
                // Handle case with no generator status
                if (!record.generatorStatus?.length) {
                    return iconTheme === 0 ? (
                        <ColorCircle color={grey} value={-1} />
                    ) : (
                        <div key={record.id}>N/A</div>
                    );
                }

                const allBatteries = record.generatorStatus
                    .flatMap(gen => gen.equipment_data
                        ?.flatMap(items => {
                            const genName = items?.data?.[0]?.equipment_name;
                            if (!genName ||
                                ['EV Charger', 'EV_Charger'].includes(genName) ||
                                items?.data?.[0]?.source_id === 'BCH-Fuel') {
                                return [];
                            }

                            const batteryData = items.data?.find(
                                item => item.value_name === 'Battery Voltage'
                            );

                            return [{
                                gen,
                                items,
                                genName,
                                batteryData,
                                genIndex: gen.site_id,
                                equipIndex: items.equipment_id
                            }];
                        })
                    )
                    .filter(Boolean)
                    .filter(batteryInfo => {
                        const { batteryData, items } = batteryInfo;
                        const voltage = batteryData?.value ?? 'N/A';
                        const notliveData = items?.LiveStatus !== 'live';

                        // Apply filters for batteryFilter as an array
                        if (!Array.isArray(batteryFilter) || batteryFilter.length === 0) {
                            return true;  // If no filters, show all
                        }

                        return batteryFilter.some(filter => {
                            switch (filter) {
                                case 'na':
                                    return !batteryData;
                                case 'noVoltage':
                                    return !notliveData && voltage === 0;
                                case 'offline':
                                    return notliveData || (voltage === 'N/A') || (voltage < 0);
                                case 'low':
                                    return !notliveData && (voltage !== 'N/A') && voltage < 10 && voltage >= 0;
                                case 'moderate':
                                    return !notliveData && (voltage !== 'N/A') && voltage >= 10 && voltage < 12;
                                case 'good':
                                    return !notliveData && (voltage !== 'N/A') && voltage >= 12;
                                default:
                                    return false;
                            }
                        });
                    })
                    .sort((a, b) => (a.genName || '').localeCompare(b.genName || ''));

                // If no batteries found matching filters, return N/A
                if (!allBatteries.length) {
                    return iconTheme === 0 ? (
                        <ColorCircle color={grey} value={-1} />
                    ) : (
                        <div key={record.id}>N/A</div>
                    );
                }

                return (
                    <Space size="small">
                        {allBatteries.map(batteryInfo => {
                            const { gen, items, genName, batteryData, genIndex, equipIndex } = batteryInfo;
                            const voltage = batteryData?.value ?? 'N/A';
                            const lastUpdated = items.data?.[0]?.value_datetime
                                ? (selectedTimezone === 'UTC'
                                    ? dayjs.utc(items.data[0].value_datetime).format('YYYY-MM-DD HH:mm:ss')
                                    : dayjs(items.data[0].value_datetime).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss'))
                                : '';
                            const notliveData = items?.LiveStatus !== 'live';

                            const status = voltage === 'N/A' ? 'Unknown/Offline' : notliveData || voltage < 0
                                ? 'Unknown/Offline'
                                : voltage === 0
                                    ? 'No Voltage'
                                    : voltage < 10
                                        ? 'Low'
                                        : voltage < 12
                                            ? 'Moderate'
                                            : 'Good';

                            const fillColor = voltage === 'N/A' ? grey : notliveData || voltage < 0
                                ? grey
                                : voltage === 0
                                    ? grey
                                    : voltage < 10
                                        ? red
                                        : voltage < 12
                                            ? '#F19D00'
                                            : green;

                            const tableData = [{
                                key: `${genIndex}-${equipIndex}`,
                                name: genName || 'Unknown',
                                batteryVoltage: voltage,
                                lastUpdated,
                                status
                            }];

                            const columns = [
                                { title: 'Name', dataIndex: 'name', key: 'name' },
                                { title: 'Battery Voltage', dataIndex: 'batteryVoltage', key: 'batteryVoltage' },
                                { title: 'Status', dataIndex: 'status', key: 'status' },
                                { title: `Last Updated (${selectedTimezoneAbb})`, dataIndex: 'lastUpdated', key: 'lastUpdated' }
                            ];

                            const key = `gen-${genIndex}-equip-${equipIndex}`;

                            return (
                                <Popover
                                    key={key}
                                    content={
                                        <Table
                                            columns={columns}
                                            dataSource={tableData}
                                            pagination={false}
                                            size="small"
                                            bordered
                                        />
                                    }
                                    color="white"
                                    styles={{ body: { padding: 0 } }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {iconTheme === 0 ? (
                                            <div
                                                onClick={() => handleGeneratorClick(
                                                    record?.name,
                                                    record.requested_schema,
                                                    items?.equipment_id,
                                                    items.data[0]?.equipment_id,
                                                    items.data[0]?.source_id,
                                                    gen.site_details?.preferences?.present_components
                                                )}
                                                style={{ cursor: 'pointer', marginRight: 5 }}
                                            >
                                                <ColorCircle
                                                    value={voltage === 'N/A' ? -1 : _.round(voltage, 0)}
                                                    color={fillColor}
                                                />
                                            </div>
                                        ) : (
                                            <span>
                                                <MainIconTemp3 fillColor={fillColor} />
                                                <div
                                                    onClick={() => handleGeneratorClick(
                                                        record?.name,
                                                        record.requested_schema,
                                                        items?.equipment_id,
                                                        items.data[0]?.equipment_id,
                                                        items.data[0]?.source_id,
                                                        gen.site_details?.preferences?.present_components
                                                    )}
                                                    style={{ cursor: 'pointer', marginLeft: 5 }}
                                                >
                                                    {genName}
                                                </div>
                                            </span>
                                        )}
                                    </div>
                                </Popover>
                            );
                        })}
                    </Space>
                );
            }


        },
        {
            title: 'COMM',
            key: '9',
            filteredValue: filter[6],
            dataIndex: 'comm',
            filters: [
                { text: 'Online', value: true },
                { text: 'Offline', value: false }
            ],
            onFilter: (value, record) => record?.location?.status === (value ? 1 : 0),
            render: (text, record) => {
                const status = record?.location?.status === 1;
                const timeStamp = record?.location?.last_update_at;

                // Determine the circle color based on the status
                const circleColor = status ? green : red;

                // Table columns for Tooltip
                const tableColumns = [
                    {
                        title: 'Last Updated ' + `(${selectedTimezoneAbb})`,
                        dataIndex: 'lastUpdated',
                        key: 'lastUpdated'
                    }
                ];

                const tableData = [
                    {
                        key: '1',
                        lastUpdated: timeStamp
                            ? selectedTimezone?.includes('UTC')
                                ? dayjs(timeStamp).format('YYYY-MM-DD HH:mm:ss')
                                : dayjs(timeStamp).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss')
                            : null
                    }
                ];

                return (
                    <Popover
                        styles={{ body: { padding: 0 } }}
                        content={
                            <Table
                                columns={tableColumns}
                                dataSource={tableData}
                                size="small"
                                pagination={false}
                                bordered
                            />
                        }
                    >
                        <span>
                            <ColorCircle color={circleColor} value={-123} />
                        </span>
                    </Popover>
                );
            },
        }
        ,
        {
            title: 'TRAVEL MODE',
            key: '10',
            filteredValue: filter[7],
            dataIndex: 'travel',
            render: () => {
                // const engineHours = record.generators[0]?.liveValues.find((item: any) => item.value_name === 'Engine Hours')?.value;
                return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : 'N/A';
            },
        },
    ];

    // const defaultCheckedList = columns.map((item) => item.key);

    const options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
        key: key
    }));

    const newColumns = columns.map((item, key) => ({
        ...item,
        key: key,
        hidden: !checkedList.includes(item.key as string),
    }));


    const onSearch = (value: string) => {
        setSearchQuery(value);
    }

    const { styles } = useStyle();

    const sortedData = useMemo(() => {
        const dataToSort = searchQuery ? generatorData : filteredData;
        return [...dataToSort].sort((a, b) =>
            a.requested_schema.localeCompare(b?.requested_schema)
        );
    }, [searchQuery, generatorData, filteredData]);

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
                <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Col span={'auto'} flex={1}>
                        <Input
                            onChange={(e) => onSearch(e.target.value)}
                            addonBefore={<SearchOutlined />}
                            placeholder="Search by location/ Available/ Run/ Etc..."
                        />
                    </Col>
                    <Col>
                        <Popover
                            content={
                                <Row gutter={[16, 16]} style={{ width: '250px' }}>
                                    <Col>
                                        <Checkbox.Group
                                            value={checkedList}
                                            options={options as CheckboxOptionType[]}
                                            onChange={(value) => {
                                                setCheckedList(value as string[]);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            }
                            title="Title"
                            trigger="click"
                            open={open}
                            onOpenChange={handleOpenChange}
                        >
                            <Button
                                type="primary"
                                icon={<EyeInvisibleOutlined />}
                            >
                                Filter Columns
                            </Button>
                        </Popover>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
                <div>
                    <Table
                        locale={{ emptyText: <Empty description={permittedCustomers.length === 0 ? "No customer data access given to your account." : "No data available"} /> }}
                        scroll={{ x: isMobile ? 1200 : true, y: 'calc(100vh - 300px)' }}
                        className={styles.customTable}
                        ref={tableRef}
                        size="small"
                        pagination={false}
                        loading={
                            (permittedCustomersLoading || loading || (loadingData && !loading)
                            )
                        }
                        key="table"
                        columns={newColumns}
                        dataSource={sortedData}
                        onChange={(pagination, filters) => {
                            setFilter(filters)
                            if (!filters[5] || filters[5].length === 0) {
                                setBatteryFilter([])
                            }
                            else {
                                setBatteryFilter(filters[5])
                            }
                            if (!filters[3] || filters[3].length === 0) {
                                setGenFilter([])
                            }
                            // if (filters[3] && filters[3].includes('running')) {
                            //     setProdClicked(true)
                            // }
                            // if (filters[3] && filters[3].includes('fault')) {
                            //     setFaultClicked(true)
                            // }
                            // if (filters[3] && filters[3].includes('ableToRun')) {
                            //     setHealthyClicked(true)
                            // }
                            else {
                                setGenFilter(filters[3])
                            }
                        }}
                    />
                </div>
            </Col>
        </Row>
    );
}

export default TableDashboardFactory;
