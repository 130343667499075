import { Card, Col, Popover, Row, Table, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { clearPowerState } from "../../redux/slices/powerSlice";
import { clearChargerData } from "../../redux/slices/chargerSlice";
import { setSelectedCustomerName, setSelectedSite } from "../../redux/slices/customerSlice";
import Pioneer from '../../assets/Dashboard/Pioneer.svg';
import Maintenance from '../../assets/Dashboard/Maintenance.svg';
import Production from '../../assets/Dashboard/Production.svg';
import Build from '../../assets/Dashboard/Builld.svg';
import Transport from '../../assets/Dashboard/Transport.svg';
import Fault from '../../assets/Dashboard/Fault.svg';
import Healthy from '../../assets/Dashboard/Healthy.svg';
import Hours from '../../assets/Dashboard/Hours.svg';
import { initiateLogin } from "../../utils/common";
import { logoutUser } from "../../redux/services/customerService";
import _ from "lodash";
import TableDashboardFactory from "./TableDashboardFactory";
import '../../App.css';
import { alarms, faults } from "../../utils/constants";



const FactoryView = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [prodClicked, setProdClicked] = useState<boolean>(false);
    const [faultClicked, setFaultClicked] = useState<boolean>(false);
    const [healthyClicked, setHealthyClicked] = useState<boolean>(false);
    const { responses } = useSelector((state: RootState) => state.dashboard);
    const defaultCheckedList = ['0', '1', '3', '4', '8', '9'];

    const [checkedList, setCheckedList] = useState(defaultCheckedList);

    const signOut = () => {
        dispatch(logoutUser()).then(() => {
            initiateLogin();
        });
    };

    function productionValue() {
        const uniqueEquipmentIds = new Set();

        responses.forEach(site => {
            site.forEach(siteData => {
                siteData.equipment_data.forEach(equipment => {
                    // Ensure the equipment is "live" and check if it's not an "EV Charger"
                    if (equipment.LiveStatus === 'live') {
                        equipment.data.forEach(item => {
                            if (item.value_name === 'Volts AB' && item.value > 100 && !/ev[\s_]*charger/i.test(item.equipment_name)) {
                                uniqueEquipmentIds.add(equipment.equipment_id); // Add to the set
                            }
                        });
                    }
                });
            });
        });

        // Return the count of unique 'Volts AB' > 100 equipment
        return uniqueEquipmentIds.size;
    }

    function buildValue() {
        const uniqueEquipmentIds = new Set();

        responses.forEach(site => {
            site.forEach(siteData => {
                siteData.equipment_data.forEach(equipment => {
                    // Ensure the equipment is "live" and check if it's not an "EV Charger"
                    {
                        equipment.data.forEach(item => {
                            if (!/ev[\s_]*charger/i.test(item.equipment_name) && !(item.source_id === 'BCH-Fuel')) {
                                uniqueEquipmentIds.add(equipment.equipment_id); // Add to the set
                            }
                        });
                    }
                });
            });
        });
        // Return the count of unique 'Volts AB' > 100 equipment
        return uniqueEquipmentIds.size;
    }

    function faultValue() {
        const uniqueEquipmentIds = new Set(); // Use a Set to store unique equipment IDs

        responses.forEach(site => {
            site.forEach(siteData => {
                siteData.equipment_data.forEach(equipment => {
                    // Ensure the equipment is "Live" (case-sensitive check for LiveStatus)
                    if (equipment.LiveStatus.toLowerCase() === 'live') {
                        equipment.data.forEach(item => {
                            // Check if the fault is active (value === 1) and equipment is not an EV charger
                            if (faults.includes(item.value_name) && item.value === 1 && !/ev[\s_]*charger/i.test(item.equipment_name)) {
                                uniqueEquipmentIds.add(equipment.equipment_id); // Add equipment ID to the Set
                            }
                        });
                    }
                });
            });
        });

        // Return the count of unique equipment IDs that have active faults
        return uniqueEquipmentIds.size;
    }


    const hoursValue = useMemo(() => {
        let totalEngineHours = 0;
        const customerEngineHours: any = {};

        responses.forEach(site => {
            const customerName = site?.[0]?.requested_schema;
            let customerTotalEngineHours = 0;

            site.forEach(siteData => {
                siteData.equipment_data.forEach(equipment => {
                    if (equipment.LiveStatus === 'live') {
                        equipment.data.forEach(item => {
                            if (item.value_name === 'Engine Hours' && item.value > 0 && !/ev[\s_]*charger/i.test(item.equipment_name)) {
                                totalEngineHours += item.value;
                                customerTotalEngineHours += item.value;
                            }
                        });
                    }
                });
            });

            if (customerName) {
                customerEngineHours[customerName] = (customerEngineHours[customerName] || 0) + customerTotalEngineHours;
            }
        });

        return { totalEngineHours, customerEngineHours }; // Return both total and per-customer mapping
    }, [responses]); // Recompute only when 'responses' changes

    const engineHoursByCustomer = hoursValue.customerEngineHours;

    function healthyValue() {
        const uniqueEquipmentIds = new Set();

        responses.forEach(site => {
            site.forEach(siteData => {
                siteData.equipment_data.forEach(equipment => {
                    // Ensure the equipment is "live"
                    if (equipment.LiveStatus === 'live') {
                        // Check the engine hour value (must not be negative)
                        const engineHourCheck = equipment.data.some(item => item.value_name === 'Engine Hours' && item.value < 0 && !/ev[\s_]*charger/i.test(item.equipment_name));
                        // Check for negative fault/alarms (value -99)
                        const faultStatusesNegative = equipment.data.some(item =>
                            item.value_name && (faults.includes(item.value_name) || alarms.includes(item.value_name)) && item.value === -99 && !/ev[\s_]*charger/i.test(item.equipment_name));

                        // Check the "Running" status (must be 1)
                        const runningStatus = equipment.data.some(item => item.value_name === 'Volts AB' && item.value > 100 && !/ev[\s_]*charger/i.test(item.equipment_name));

                        // Check if there are any faults or alarms
                        const faultStatuses = equipment.data.filter(item => faults.includes(item.value_name) && item.value === 1 && !/ev[\s_]*charger/i.test(item.equipment_name));
                        const alarmStatuses = equipment.data.filter(item => alarms.includes(item.value_name) && item.value === 1 && !/ev[\s_]*charger/i.test(item.equipment_name) && !['Common Alarm', 'Warning Alarm'].includes(item.value_name));

                        // If equipment is healthy (green)
                        if (!engineHourCheck && !faultStatusesNegative && runningStatus && faultStatuses.length === 0 && alarmStatuses.length === 0) {
                            uniqueEquipmentIds.add(equipment.equipment_id); // Add to the set of unique healthy equipment
                        }
                    }
                });
            });
        });

        // Return the count of unique healthy equipment
        return uniqueEquipmentIds.size;
    }

    const CardComponent = ({ img, title, value, clickable, onClick, clicked }: { img: any; title: string; value: any, clickable?: boolean, onClick?: any, clicked?: boolean }) => {
        return (
            <Card hoverable={clickable} size="small" style={clicked ? { background: 'aliceblue' } : {}} className={clicked ? "NoHeaderBorder heightCard noPaddingBodyCard" : "NoHeaderBorder heightCard noPaddingBodyCard"} bordered={false} extra={<img src={img} alt="icon" />} title={title} onClick={clickable ? onClick : null}>
                <Typography.Text className='text-32 font-bold' type="success">{value}</Typography.Text>
            </Card>
        )
    }
    const responsiveCol = checkedList.length <= 6 ? {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 4,
        xxl: 4
    } :
        {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 3,
            xxl: 3
        }

    const dataSource = Object.entries(engineHoursByCustomer).map(([key, value], index) => ({
        key: index,
        customer: key,
        engineHours: typeof value === 'number' ? _.round(value, 2) : 'N/A',
    }));

    // Define the columns for the table
    const columns = [
        {
            title: "Customer Name",
            dataIndex: "customer",
            key: "customer",
        },
        {
            title: "Engine Hours",
            dataIndex: "engineHours",
            key: "engineHours",
        },
    ];


    return (
        <Row gutter={[16, 16]} >
            <Col {...responsiveCol}><Card className="heightCard" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} size="small" bordered={false}><img src={Pioneer} alt="icon" className="imgCommon" /></Card></Col>
            <Col {...responsiveCol}><CardComponent img={Build} title={'TOTAL UNITS'} value={buildValue()} /></Col>
            <Col {...responsiveCol}><CardComponent img={Production} title={'IN USE'} clickable={true} onClick={() => setProdClicked(!prodClicked)} value={productionValue()} clicked={prodClicked} /></Col>
            {checkedList.includes('10') && <Col {...responsiveCol}><CardComponent img={Transport} title={'TRANSPORT'} value={'N/A'} /></Col>}
            <Col {...responsiveCol}><CardComponent img={Healthy} title={'HEALTHY UNITS'} value={healthyValue()} clickable={true} onClick={() => setHealthyClicked(!healthyClicked)} clicked={healthyClicked} /></Col>
            <Col {...responsiveCol}><CardComponent img={Fault} title={'FAULTED UNITS'} value={faultValue()} clickable={true} onClick={() => setFaultClicked(!faultClicked)} clicked={faultClicked} /></Col>
            {checkedList.includes('5') && <Col {...responsiveCol}><CardComponent img={Maintenance} title={'MAINTENANCE (WEEK)'} value={'N/A'} /></Col>}
            <Col {...responsiveCol}>

                <Popover
                    content={<Table dataSource={dataSource} columns={columns} pagination={false} size="small" />}
                    title="Engine Hours by Customer (Active/Live)"
                ><div>
                        <CardComponent img={Hours} title={'HOURS'} value={_.round(hoursValue.totalEngineHours, 2)} />
                    </div>
                </Popover>
            </Col>
            <Col span={24}>
                <TableDashboardFactory prodClicked={prodClicked} setProdClicked={setProdClicked} faultClicked={faultClicked} setFaultClicked={setFaultClicked} healthyClicked={healthyClicked} setHealthyClicked={setHealthyClicked} setCheckedList={setCheckedList} checkedList={checkedList} />
            </Col>
        </Row>
    )
};

export default FactoryView