import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './App.css';
import { ALARMS, CAMERAS, CHARGERS, DOCS, FUEL, GENERAL, MAINTENANCE_SCREEN, MAPS, POWER_GENERATION, REPORTS, SOLAR, STARLINK } from './utils/constants';
import { Col, ConfigProvider, Row, Typography } from 'antd';

import AppContent from './view/auth/AppContent';
import ErrorPage from './view/auth/ErrorPage';
import Dashboard from './view/dashboard/Dashboard';
import GlobalDashboard3 from './view/general/GlobalDashboard3';
import General from './view/general/General';
import Chargers from './view/charger/Chargers';
import IndividualCharger from './view/charger/IndividualCharger';
import Maps from './view/maps/Maps';
import Power from './view/power/Power';
import PowerIndividual from './view/power/PowerIndividual';
import Fuel from './view/fuel/Fuel';
import FuelIndividual from './view/fuel/FuelIndividual';
import Solar from './view/solar/Solar';
import Reports from './view/reports/Reports';
import Alarms from './view/alarms/Alarms';
import Camera2 from './view/cameras/Cameras2';
import StarLink from './view/starlink/Starlink';
import Docs from './view/docs/Docs';
import ErrorLoginPage from './view/error/errorLogin';
import NoPermissionPage from './NoPermissionPage';
import FactoryViewWrapper from './view/general/FactoryViewWrapper';
import FactoryView from './view/general/FactoryView';
import MaintenanceScreen from './view/general/MaintenanceScreen';
import ProtectedRoute from './ProtectedRoute';

const toLowerCasePath = (path: string) => path.toLowerCase();

const LoadingFallback = (
  <Row justify="center">
    <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
      <Typography.Title level={2} type="secondary">
        Loading. Please wait!
      </Typography.Title>
    </Col>
  </Row>
);

const protectedRoutes = [
  { path: CHARGERS, element: <ProtectedRoute route={CHARGERS}><Chargers /></ProtectedRoute>, children: [{ path: ":chargerId", element: <IndividualCharger /> }] },
  { path: MAPS, element: <ProtectedRoute route={MAPS}><Maps /></ProtectedRoute> },
  { path: POWER_GENERATION, element: <ProtectedRoute route={POWER_GENERATION}><Power /></ProtectedRoute>, children: [{ path: ":powerId", element: <PowerIndividual /> }] },
  { path: FUEL, element: <ProtectedRoute route={FUEL}><Fuel /></ProtectedRoute>, children: [{ path: ":fuelId", element: <FuelIndividual /> }] },
  { path: SOLAR, element: <ProtectedRoute route={SOLAR}><Solar /></ProtectedRoute> },
  { path: REPORTS, element: <ProtectedRoute route={REPORTS}><Reports /></ProtectedRoute> },
  { path: CAMERAS, element: <ProtectedRoute route={CAMERAS}><Camera2 /></ProtectedRoute> },
  { path: ALARMS, element: <ProtectedRoute route={ALARMS}><Alarms /></ProtectedRoute> },
  { path: STARLINK, element: <ProtectedRoute route={STARLINK}><StarLink /></ProtectedRoute> },
  { path: DOCS, element: <ProtectedRoute route={DOCS}><Docs /></ProtectedRoute> },
];

const router = createBrowserRouter([
  { path: '/403', element: <NoPermissionPage /> },
  {
    path: "/",
    element: <AppContent />,
    errorElement: <ErrorPage />,
    children: [
      { path: ":customer", element: <GlobalDashboard3 /> },
      // { path: toLowerCasePath(":customer/" + MAINTENANCE_SCREEN), element: <MaintenanceScreen /> },
      {
        path: ":customer/view/FactoryDashboard",
        element: (
          <FactoryViewWrapper>
            <FactoryView />
          </FactoryViewWrapper>
        ),
      },
      {
        path: ":customer/:site",
        element: <Dashboard />,
        children: [
          { index: true, element: <Navigate to={toLowerCasePath(GENERAL)} replace /> },
          { path: toLowerCasePath(GENERAL), element: <General /> },
          // { path: toLowerCasePath(MAINTENANCE_SCREEN), element: <MaintenanceScreen /> },
          ...protectedRoutes.map(({ path, element, children }) => ({
            path: toLowerCasePath(path),
            element,
            children: children || [],
          })),
          { path: "errorLogin", element: <ErrorLoginPage /> },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <ConfigProvider theme={{ token: { fontFamily: "SF Pro Display" } }}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
