import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError, CancelTokenSource } from "axios";
import { detailedChargerDataInterface, SessionData } from "../types/chargerTypes";
import dayjs from "dayjs";
import { FetchBPLiveDataPayload, detailedGeneratorData } from "../types/powerTypes";

interface FetchSessionsPayload2 {
    fromDate?: string;
    toDate?: string;
    selectedCustomerName: string;
    site_id?: number
}

const protocol: any = process.env.REACT_APP_PROTOCOL;
const url: any = process.env.REACT_APP_API_URL;
const baseUrl: any = protocol + url;

let cancelTokenSource11: CancelTokenSource | null = null;
let chargerPollingInterval: NodeJS.Timeout | null = null;

// Async thunk to fetch detailed summary charger data
export const fetchdetailedSummaryCharger = createAsyncThunk<
    detailedChargerDataInterface, // Return type
    FetchSessionsPayload2, // Argument type
    { rejectValue: string } // Optional reject value
>(
    'sites/fetchdetailedSummaryCharger',
    async (payload: FetchSessionsPayload2, thunkAPI) => {
        const { fromDate, toDate, selectedCustomerName } = payload;

        // If there's already an ongoing request, cancel it
        if (cancelTokenSource11) {
            cancelTokenSource11.cancel('Request canceled due to new request');
        }

        // Create a new cancel token for the current request
        cancelTokenSource11 = axios.CancelToken.source();

        const fromrange = fromDate ?? dayjs().subtract(7, 'day').toISOString();
        const torange = toDate ?? dayjs().toISOString();

        try {
            // API call
            const response = await axios.get<detailedChargerDataInterface>(
                `${baseUrl}charger/detailed_summary/${selectedCustomerName}/?from_date=${fromrange}&to_date=${torange}`,
                {
                    withCredentials: true,
                    headers: {
                        customer: selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource11.token, // Use the new cancel token
                }
            );
            if (response.status === 200) {
                startPollingChargerSummary(payload, thunkAPI.dispatch);
            }
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', (error as AxiosError).message);
                return thunkAPI.rejectWithValue('Request canceled'); // Optional: you can return an empty array or specific error message
            }
            return thunkAPI.rejectWithValue((error as AxiosError).message);
        }
    }
);

// Function to start polling
export const startPollingChargerSummary = (
    payload: FetchSessionsPayload2,
    dispatch: any,
    interval: number = 300000  // Default 3 minutes
) => {
    // Clear any existing polling
    stopPollingChargerSummary();

    // Start a new polling interval
    chargerPollingInterval = setInterval(() => {
        dispatch(fetchdetailedSummaryCharger(payload));
    }, interval);

    // Immediately fetch data before the first interval
    // dispatch(fetchdetailedSummaryCharger(payload));
};

// Function to stop polling
export const stopPollingChargerSummary = () => {
    if (chargerPollingInterval) {
        clearInterval(chargerPollingInterval);
        chargerPollingInterval = null;
    }
    if (cancelTokenSource11) {
        cancelTokenSource11.cancel('Request canceled');
        cancelTokenSource11 = null;
    }
};

let cancelTokenSource12: CancelTokenSource | null = null;

export const fetchdetailedSummaryChargerForSite = createAsyncThunk<
    detailedChargerDataInterface, // Return type (array of SessionData)
    FetchSessionsPayload2, // Argument type
    { rejectValue: string } // Optional reject value
>(
    'sites/fetchdetailedSummaryChargerForSite',
    async (payload: FetchSessionsPayload2, thunkAPI) => {
        const { fromDate, toDate, selectedCustomerName, site_id } = payload;

        // If there's already an ongoing request, cancel it
        if (cancelTokenSource12) {
            cancelTokenSource12.cancel('Request canceled due to new request');
        }

        // Create a new cancel token for the current request
        cancelTokenSource12 = axios.CancelToken.source();

        const fromrange = fromDate ?? dayjs().subtract(1, 'day').toISOString();
        const torange = toDate ?? dayjs().toISOString();

        try {
            // API call
            const { data } = await axios.get<detailedChargerDataInterface>(
                `${baseUrl}charger/detailed_summary/${selectedCustomerName}/?from_date=${fromrange}&to_date=${torange}&siteId=${site_id}`,
                {
                    withCredentials: true,
                    headers: {
                        customer: selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource12.token, // Use the new cancel token
                }
            );

            return data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', (error as AxiosError).message);
                return thunkAPI.rejectWithValue('Request canceled'); // Optional: you can return an empty array or specific error message
            }
            return thunkAPI.rejectWithValue((error as AxiosError).message);
        }
    }
);

let cancelTokenSource: CancelTokenSource | null = null;
let bpPollingInterval: NodeJS.Timeout | null = null;

// Define payload type
export type FetchBPLiveDataPayload2 = {
    selectedCustomerName: string;
    site_id?: number;
};

// Async thunk to fetch BP live data for customer
export const fetchBPLiveDataForCustomer = createAsyncThunk<
    detailedGeneratorData[], // Return type
    FetchBPLiveDataPayload2, // Argument type
    { rejectValue: string }  // Optional reject value
>(
    'sites/fetchBPLiveDataForCustomer',
    async (payload, thunkAPI) => {
        const { selectedCustomerName } = payload;

        // Cancel ongoing request if any
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Request canceled due to new request');
        }

        // Create a new cancel token for the current request
        cancelTokenSource = axios.CancelToken.source();

        try {
            const response = await axios.get<detailedGeneratorData[]>(
                `${baseUrl}trend/live_values_v3`,
                {
                    withCredentials: true,
                    headers: {
                        customer: selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource.token, // Use the new cancel token
                }
            );
            if (response.status === 200) {
                startPollingBPLiveData(payload, thunkAPI.dispatch);
            }
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', (error as AxiosError).message);
                return thunkAPI.rejectWithValue('Request canceled');
            }
            return thunkAPI.rejectWithValue((error as AxiosError).message);
        }
    }
);

export type FetchMaintenanceHistoryPayload = {
    requestedSchema: string;
    selectedSite: any;
};

export interface Site {
    id: number;
    name: string;
}

export interface Equipment {
    id: number;
    name: string;
}

export interface MaintenanceData {
    site: Site;
    type: string;
    status: string;
    vendor: string;
    percent: string;
    comments: string;
    contacts: string[];
    customer: string;
    nameType: string;
    equipment: Equipment;
    frequency: string;
    engineHour: string;
    lastService: string;
    ticket_type: string;
    equipment_name: string;
    ticket_cf_customer: string;
}

export interface Frequency {
    type: string;
    interval: number;
}

export interface MaintenanceHistory {
    register_id: number;
    equipment_id: number;
    maintenance_type: string;
    service_type: string;
    active: boolean;
    frequency: Frequency | null;
    alert_threshold: number;
    initial_value: number;
    created_at: string;
    updated_at: string;
    site: number;
}

export interface TicketData {
    ticket_subject: string;
    ticket_cf_details: string | null;
    ticket_cf_comments: string;
    ticket_cf_customer: string;
    ticket_cf_provider: string | null;
    ticket_description: string;
    ticket_ticket_type: string | null;
    ticket_cf_equipment: number;
    ticket_cf_date_added: string | null;
    ticket_cf_vendor_to_use: string;
    ticket_cf_frequency627071: number;
    ticket_cf_maintenance_type: string;
    ticket_cf_current_engine_hour744179: number;
    ticket_cf_last_service_in_hours599406: number;
    ticket_cf_percentage_in_advance247362: number;
    ticket_cf_type_of_repetitative_service: string;
}

export interface SiteLocation {
    source: string;
    status: number;
    latitude: number;
    longitude: number;
    last_update_at: string;
}

export interface SiteInfo {
    id: number;
    name: string;
    bp_siteinfo: {
        bpsiteid: number;
        sitename: string;
    };
    teltonika_info: {
        id: number;
        name: string;
    };
    description: string;
    location: SiteLocation;
    victron_link: string;
    victron_id: number | null;
    communication_link: string;
    preferences: any;
}

export interface Frequency {
    type: string;
    interval: number;
}

export interface MaintenanceRegister {
    register_id: number;
    equipment_id: number;
    maintenance_type: string;
    service_type: string;
    active: boolean;
    frequency: Frequency;
    alert_threshold: number;
    initial_value: number;
    created_at: string;
    updated_at: string;
    site: number;
}

export interface TicketHistory {
    id: number;
    ticket_id: number;
    ticket_type: string;
    status: string;
    created_at: string;
    updated_at: string;
    data: TicketData;
    site: SiteInfo;
    maint_register: MaintenanceRegister;
}


export interface MaintenanceState {
    maintenanceHistory: TicketHistory[];
}



export const fetchMaintRegistry = createAsyncThunk<
    MaintenanceHistory[], // Return type
    FetchMaintenanceHistoryPayload, // Argument type
    { rejectValue: string }  // Optional reject value
>(
    "maintenance/fetchHistory",
    async (payload, thunkAPI) => {
        try {
            // Construct the URL dynamically
            const url =
                // payload.selectedSite
                // ? `${baseUrl}maintenance/maint_register/${payload.selectedSite}/`
                `${baseUrl}maintenance/maint_register/`;

            const response = await axios.get<MaintenanceHistory[]>(url, {
                withCredentials: true,
                headers: {
                    customer: payload.requestedSchema,
                },
            });

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                axios.isAxiosError(error) ? error.message : "An unknown error occurred"
            );
        }
    }
);

export const fetchMaintenanceTickets = createAsyncThunk<
    MaintenanceHistory[], // Return type
    FetchMaintenanceHistoryPayload, // Argument type
    { rejectValue: string }  // Optional reject value
>(
    "maintenance/fetchMaintenanceTickets",
    async (payload, thunkAPI) => {
        try {
            // Construct the URL dynamically
            const url =
                payload.selectedSite
                    ? `${baseUrl}maintenance/get_ticket/${payload.selectedSite}/` :
                    `${baseUrl}maintenance/get_ticket/`;

            const response = await axios.get<MaintenanceHistory[]>(url, {
                withCredentials: true,
                headers: {
                    customer: payload.requestedSchema,
                },
            });

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                axios.isAxiosError(error) ? error.message : "An unknown error occurred"
            );
        }
    }
);

export interface FetchMaintenanceThresholdPayload {
    site?: number;
    selectedCustomerName: string;
}

export interface Site {
    id: number;
    name: string;
}

export interface Equipment {
    id: number;
    name: string;
}

export interface MaintenanceThresholdRecord {
    id: number;
    site: Site;
    current_engine_hours: number;
    equipment: Equipment;
    last_maintenance_hours: number;
    maintenance_threshold_hours: number;
    alert_threshold: number;
}

export interface MaintenanceThreshold2 {
    customer: string;
    data: MaintenanceThresholdRecord[];
}

// Async thunk action
export const fetchMaintenanceThreshold = createAsyncThunk<
    MaintenanceThresholdRecord[],  // Return type
    FetchMaintenanceThresholdPayload, // Payload type
    { rejectValue: string } // Reject type
>(
    "maintenance/fetchThreshold",
    async (payload, thunkAPI) => {
        try {
            const { site, selectedCustomerName } = payload;
            const url = site ? `${baseUrl}maintenance/threshold/${site}/` : `${baseUrl}maintenance/threshold/`;

            const response = await axios.get<MaintenanceThresholdRecord[]>(url, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    customer: selectedCustomerName,
                },
            });

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                axios.isAxiosError(error) ? error.message : "An unknown error occurred"
            );
        }
    }
);

// Function to start polling
export const startPollingBPLiveData = (
    payload: FetchBPLiveDataPayload2,
    dispatch: any,
    interval: number = 300000 // Default 5 minutes
) => {
    // Clear any existing polling
    stopPollingBPLiveData();

    // Start a new polling interval
    bpPollingInterval = setInterval(() => {
        dispatch(fetchBPLiveDataForCustomer(payload));
    }, interval);

    // Immediately fetch data before the first interval
    // dispatch(fetchBPLiveDataForCustomer(payload));
};

// Function to stop polling
export const stopPollingBPLiveData = () => {
    if (bpPollingInterval) {
        clearInterval(bpPollingInterval);
        bpPollingInterval = null;
    }
    if (cancelTokenSource) {
        cancelTokenSource.cancel('Request canceled');
        cancelTokenSource = null;
    }
};

let cancelTokenSource1: CancelTokenSource | null = null;

export const fetchBPLiveDataForCustomerForOneSite = createAsyncThunk<detailedGeneratorData[], FetchBPLiveDataPayload2, { rejectValue: string }>(
    'sites/fetchBPLiveDataForCustomerForOneSite',
    async (payload, thunkAPI) => {
        const { selectedCustomerName, site_id } = payload;

        if (cancelTokenSource1) {
            cancelTokenSource1.cancel('Request canceled');
        }

        cancelTokenSource1 = axios.CancelToken.source();

        try {
            const { data } = await axios.get<detailedGeneratorData[]>(
                `${baseUrl}trend/live_values_v3?siteId=${site_id}`,
                {
                    withCredentials: true,
                    headers: {
                        'customer': selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource1.token,
                }
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

let cancelTokenSource7: CancelTokenSource | null = null;
let bpPollingInterval7: NodeJS.Timeout | null = null;

// Define payload type
export type FetchBPLiveDataPayload7 = {
    schemas: string[];
};

// Async thunk to fetch BP live data for customer
export const fetchBPLiveDataForCustomerFactory = createAsyncThunk<
    detailedGeneratorData[], // Return type
    FetchBPLiveDataPayload7, // Argument type
    { rejectValue: string }  // Optional reject value
>(
    'sites/fetchBPLiveDataForCustomerFactory',
    async (payload, thunkAPI) => {
        // Cancel ongoing request if any
        if (cancelTokenSource7) {
            cancelTokenSource7.cancel('Request canceled due to new request');
        }

        // Create a new cancel token for the current request
        cancelTokenSource7 = axios.CancelToken.source();

        try {


            const response = await axios.post<detailedGeneratorData[]>(
                `${baseUrl}trend/live_values_v4`,
                { schemas: payload.schemas }, {
                withCredentials: true,
                cancelToken: cancelTokenSource7.token, // Use the new cancel token
            }
            );
            if (response.status === 200) {
                startPollingBPLiveDataFactory(payload, thunkAPI.dispatch);
            }
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', (error as AxiosError).message);
                return thunkAPI.rejectWithValue('Request canceled');
            }
            return thunkAPI.rejectWithValue((error as AxiosError).message);
        }
    }
);

// Function to start polling
export const startPollingBPLiveDataFactory = (
    payload: FetchBPLiveDataPayload7,
    dispatch: any,
    interval: number = 300000 // Default 5 minutes
) => {
    // Clear any existing polling
    stopPollingBPLiveDataFactory();

    // Start a new polling interval
    bpPollingInterval7 = setInterval(() => {
        dispatch(fetchBPLiveDataForCustomerFactory(payload));
    }, interval);

    // Immediately fetch data before the first interval
    // dispatch(fetchBPLiveDataForCustomer(payload));
};

// Function to stop polling
export const stopPollingBPLiveDataFactory = () => {
    if (bpPollingInterval7) {
        clearInterval(bpPollingInterval7);
        bpPollingInterval7 = null;
    }
    if (cancelTokenSource7) {
        cancelTokenSource7.cancel('Request canceled');
        cancelTokenSource7 = null;
    }
};
